import './AddToCart.scss';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Button, CardBody, CardHeader, CardFooter, Modal,
  ModalHeader,
  ModalBody, Spinner } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useDispatch } from 'react-redux';
import { SuccessToast, ErrorToast } from '../../../../../utils/Toaster';
import { Toaster } from 'react-hot-toast';
import { addOrUpdateBulkCart, addOrUpdateCart, removeItemFromCart } from '../../actions/action';
import { setPageLoader } from '../../../../../Redux/order/createOrderSlice';
import { Card } from 'react-bootstrap';
import { SelectPicker } from 'rsuite';
import { useLocation, useHistory } from "react-router-dom";
import axios from 'axios';
import UserAuthKey from "../../../../../components/Common/UserAuthKey";
import Joyride from "react-joyride";
import bulkSkuUploadFile from '../../../../../assets/files/bulkSkuUpload.csv';
import * as XLSX from 'xlsx';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function CreateNewOrderComponent(props) {
  const {
    cart = {},
    showcart,
    setShowmapandAddress,
    setShowShippingControll,
  } = props || {};
  const { countryInfo } = cart || {};
  const cartItems = cart.items || [];
  const [customPrices, setCustomPrices] = useState({});
  const [disableNextToAddress, setDisableNextToAddress] = useState(false);
  const [cartItemClone, setCartItemClone] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [bulkMode, setBulkMode] = useState(false);
  const [skus, setSkus] = useState([]);
  const fileInputRef = useRef(null);
  const [bulkData, setBulkData] = useState(false);
  const [customPricesEnable, setCustomEnablePrices] = useState(false);



  const [selectedFile, setSelectedFile] = useState(null);
    const [errorMsg, setErrorMsg] = useState(false);


  const [isLoading, setIsLoading] = useState(false);
  const omsServiceapiUrl = process.env.REACT_APP_OMS_NEST_API;
  const bearerToken = UserAuthKey();
  let userEmail= ''; 
  if (localStorage.getItem("authUser")) {
    const userObj = JSON.parse(localStorage.getItem("authUser"));
    if (userObj?.role_id !== undefined) {
      userEmail = userObj?.user_email;
    }
  }

  const query = useQuery();
  const quotationId = query.get('quotation_id');

  const [editModal, setEditModal] = useState(false);
  const [quoteId, setQuotationId] = useState(false);

  function editModalModal(quotationId) {
    setEditModal(!editModal);
    setQuotationId(quotationId);
  }

  const fetchQuoteData = useCallback(async (quotationId) => {

    setIsLoading(true);

    try {

        const payload = {
            quotationId: quotationId,
        };
    
          const response = await axios.post(`${omsServiceapiUrl}/quotations/fetch-single-quote`, payload);

            const { products } = response.data || {};

            // Extract SKU and quantity, ensuring valid numeric values
            let skuList = (products || [])
            .map((product) => ({
              sku: product?.sku,
              qty: product?.qty ?? 1, // Default to 1 if qty is not available
            }))
            .filter((item) => /^\d+$/.test(item.sku)); // Keep only numeric SKUs

          // Limit to max 100 items
          if (skuList.length > 100) {
            skuList = skuList.slice(0, 100);
          }

          // Set the SKUs as an array of objects
          setSkus(skuList);

    } catch (error) {
    console.error('Failed to fetch product:', error);
    }

    setTimeout(() => {
    setIsLoading(false);
    }, 400);
}, []);

  useEffect(() => {
    if (quotationId) {
        fetchQuoteData(quotationId);
        editModalModal(quotationId)
    }
  }, [quotationId, fetchQuoteData]);

  const handleChange = (e) => {
    let input = e.target.value;

    // Split by new lines, spaces, or commasGUEST_USER_CART_TOKEN_KEY
    let skuList = input.split(/\s+|,|\n/).filter(sku => sku.trim() !== "");

    // Allow only numbers
    skuList = skuList.filter(sku => /^\d+$/.test(sku));

    // Limit to max 100 SKUs
    if (skuList.length > 100) {
        skuList = skuList.slice(0, 100);
    }

    const formattedSkus = skuList.join("\n");

    setSkus(formattedSkus); // Update local state
  };

  const handleKeyDown = (e) => {
      // Allow "Enter" to add a new line
      if (e.key === "Enter") {
          const currentValue = e.target.value;
          const newValue = currentValue + "\n"; // Add a new line
          setSkus(newValue);
          e.preventDefault(); // Prevent form submission on enter
      }
  };

  useEffect(() => {
    setCartItemClone(cart?.items || []);
    setShowShippingControll(true);
  }, [cart]);

  const dispatch = useDispatch();
  const addToCartQty = async (event, values, cp = null) => {
    event.preventDefault();
    dispatch(setPageLoader(true));
    const { sku, qty } = values;
    const cartUpdated = await addOrUpdateCart(
      { sku, qty: Number(qty || 1), cp },
      dispatch
    );
    if (cartUpdated?._id) {
      SuccessToast('New Item Add/Update to cart');
      dispatch(setPageLoader(false));
    } else {
      ErrorToast('Requested Qty not available');
      dispatch(setPageLoader(false));
    }
  };

  // const addToCartBulkQty = async (event, values, cp = null) => {
  //   event.preventDefault();
  //   dispatch(setPageLoader(true));

    

  //   const skusString = skus || ""; // Ensure it's a string
  //   if (skusString.length === 0) {
  //     ErrorToast("Please enter at least one SKU");
  //     dispatch(setPageLoader(false));
  //     return;
  //   }

  //   const skusArray = skusString
  //     .split("\n")
  //     .map(sku => sku.trim())
  //     .filter(sku => sku !== ""); // Remove empty lines

  //   if (skusArray.length === 0) {
  //       ErrorToast("Please enter at least one valid SKU");
  //       dispatch(setPageLoader(false));
  //       return;
  //   }

  //   let allSuccess = true;

  //   for (const sku of skusArray) {
  //     const cartUpdated = await addOrUpdateBulkCart(
  //       { sku, qty: 1, cp },  // Assuming default qty = 1
  //       dispatch
  //     );
  //     if (!cartUpdated?._id) {
  //         allSuccess = false;
  //     }
  //   }

  //   if (allSuccess) {
  //     SuccessToast("All items added/updated to cart");
  //     setSkus([]);
  //     if(quotationId){
  //       editModalModal()
  //     }
  //   } else {
  //       ErrorToast("Some items could not be added");
  //   }

  //   dispatch(setPageLoader(false));
  // };

  const addToCartBulkQty = async (event, values, cp = null) => {
    event.preventDefault();
    dispatch(setPageLoader(true));
  
    let itemsToProcess = [];
  
    if (selectedFile && bulkData.length > 0) {
      // bulkData.forEach((item) => {
      //     setCustomPrices((prevState) => ({
      //       ...prevState,
      //       [item.sku]: true, // Automatically check the box for this item
      //     }));
      // });
      const updatedCustomPrices = bulkData.reduce((acc, item) => {
        if (item.customPrice > 0) {
            acc[item.sku] = true; // Enable only if customPrice > 0
        }
        return acc;
    }, {});
    setCustomPrices((prevState) => ({
      ...prevState,
      ...updatedCustomPrices
  }));
      itemsToProcess = bulkData;
    } else {
      const skusArray = (skus || "")
        .split("\n")
        .map(sku => ({ sku: sku.trim(), qty: 1, customPrice: cp }))
        .filter(item => item.sku);
  
      if (skusArray.length === 0) {
        ErrorToast("Please enter at least one valid SKU");
        dispatch(setPageLoader(false));
        return;
      }
  
      itemsToProcess = skusArray;
    }
  
    let allSuccess = true;
  
    for (const item of itemsToProcess) {
      const cartUpdated = await addOrUpdateBulkCart(
        { sku: item.sku, qty: item.qty, cp: item.customPrice },
        dispatch
      );
  
      if (!cartUpdated?._id) {
        allSuccess = false;
      }
    }
  
    if (allSuccess) {
      SuccessToast("All items added/updated to cart");
      setSkus("");
      setSelectedFile(null);
      setBulkData([]);
      if (quotationId) {
        editModalModal();
      }
    } else {
      ErrorToast("Some items could not be added");
    }
  
    dispatch(setPageLoader(false));
  };

  const addToCartBulkFromQuoteQty = async (event, values, cp = null) => {
    event.preventDefault();
    dispatch(setPageLoader(true));
  
    if (!Array.isArray(skus) || skus.length === 0) {
      ErrorToast("Please enter at least one SKU");
      dispatch(setPageLoader(false));
      return;
    }
  
    let allSuccess = true;
  
    for (const item of skus) {
      const { sku, qty } = item; // Extract SKU and quantity
  
      const cartUpdated = await addOrUpdateBulkCart(
        { sku, qty: qty || 1, cp }, // Use provided qty or default to 1
        dispatch
      );
  
      if (!cartUpdated?._id) {
        allSuccess = false;
      }
    }
  
    if (allSuccess) {
      SuccessToast("All items added/updated to cart");
      setSkus([]);
  
      if (quotationId) {
        editModalModal();
      }
    } else {
      ErrorToast("Some items could not be added");
    }
  
    dispatch(setPageLoader(false));
  };
  
  const updateCustomPrice = async (index) => {
    if (cartItemClone[index].sku === '990700100004' && !selectedService) {
      ErrorToast('Please choose a service name');
    } else {
      const cSkuvalue = cartItemClone[index].sku;
      const cPricevalue =
        cartItemClone[index].product.selectedPrice.specialPrice;
      const cQtyvalue = cartItemClone[index].qty;
      if (Number(cPricevalue) > 0) {
        dispatch(setPageLoader(true));
        setDisableNextToAddress(false);
        const cartUpdated = await addOrUpdateCart(
          {
            sku: cSkuvalue,
            qty: Number(cQtyvalue),
            cp: Number(cPricevalue),
            extraInfo: JSON.stringify({ serviceType: selectedService }),
          },
          dispatch
        );
        if (cartUpdated) {
          dispatch(setPageLoader(false));
          SuccessToast('Custom price updated');
        } else {
          dispatch(setPageLoader(false));
          ErrorToast('Requested Qty not avaialable');
        }
      } else {
        ErrorToast('Custom price cannot be empty');
      }
    }
  };

  const removeItem = (itemId) => {
    dispatch(setPageLoader(true));
    const removeCartItem = async (itemId) => {
      const removeItemRes = await removeItemFromCart({ itemId }, dispatch);
      if (removeItemRes) {
        SuccessToast('Item Removed from Cart');
        dispatch(setPageLoader(false));
      } else {
        ErrorToast('Item Remove error');
        dispatch(setPageLoader(false));
      }
    };
    removeCartItem(itemId);
  };

  const customPriceCheckboxChange = async (e, itemId, index, cp = null) => {
    setDisableNextToAddress(false);
    const isChecked = e.target.checked;
    if (isChecked) {
      setDisableNextToAddress(true);
      dispatch(setPageLoader(false));
    } else {
      const updatedCartItems = [...cartItemClone]; // Create a copy of the array
      updatedCartItems[index] = {
        ...updatedCartItems[index], // Create a copy of the item
        product: {
          ...updatedCartItems[index].product,
          selectedPrice: {
            ...updatedCartItems[index].product.selectedPrice, // Create a copy of the selectedPrice
            specialPrice: cartItems[index].product.selectedPrice.specialPrice, // Update the specialPrice
          },
          cart: {
            ...updatedCartItems[index].product.cart,
            subTotal:
              Number(updatedCartItems[index].qty) *
              Number(cartItems[index].product.selectedPrice.specialPrice),
          },
        },
      };
      setCartItemClone(updatedCartItems); // Update the state
    }
    setCustomPrices((prevState) => ({
      ...prevState,
      [itemId]: isChecked ? '' : undefined,
    }));
  };

  const ChangeCustomQty = (e, index) => {
    let newQty = parseInt(e.target.value);
    if (isNaN(newQty)) {
      newQty = 0;
    }
    //if (!isNaN(newQty) && newQty >= 0) {
    const updatedCartItems = [...cartItemClone]; // Create a copy of the array
    updatedCartItems[index] = {
      ...updatedCartItems[index], // Create a copy of the item
      qty: newQty, // Update the quantity
      product: {
        ...updatedCartItems[index].product,
        cart: {
          ...updatedCartItems[index].product.cart,
          subTotal:
            newQty * updatedCartItems[index].product.selectedPrice.specialPrice,
        },
      },
    };
    setCartItemClone(updatedCartItems); // Update the state
    //}
  };

  const ChangeCustomPrice = (e, index) => {
    let newPrice = e.target.value;
    if (isNaN(newPrice)) {
      newPrice = 0;
    }
    console.log(newPrice, 'newPrice');
    const updatedCartItems = [...cartItemClone]; // Create a copy of the array
    updatedCartItems[index] = {
      ...updatedCartItems[index], // Create a copy of the item
      product: {
        ...updatedCartItems[index].product, // Create a copy of the product
        selectedPrice: {
          ...updatedCartItems[index].product.selectedPrice, // Create a copy of the selectedPrice
          specialPrice: newPrice, // Update the specialPrice
        },
        cart: {
          ...updatedCartItems[index].product.cart,
          subTotal: newPrice * updatedCartItems[index].qty,
        },
      },
    };
    setCartItemClone(updatedCartItems); // Update the state
  };

  const serviceOptions = [
    { label: 'Installation Service Fee', value: 'Installation Service Fee' },
    { label: 'Storage Fee', value: 'Storage Fee' },
    { label: 'Inspection Fee', value: 'Inspection Fee' },
    {
      label: 'Out of Warranty Service Fee',
      value: 'Out of Warranty Service Fee',
    },
    { label: 'Delivery Fee', value: 'Delivery Fee' },
  ];

  const handleSelectServiceType = (value) => {
    setSelectedService(value);
  };

  const [isClosed, setIsClosed] = useState(false);
  const [isJoyrideActive, setIsJoyrideActive] = useState(true);

  const [steps] = useState([
    {
      target: ".newFeatureBloc", // Selector for the feature block
      content: "New Feature: Bulk Add Products Now Available!",
      placement: "bottom", // Position of the tooltip
      disableBeacon: true, // Disables the automatic beacon (indicator) for this step
    },
    {
      target: ".bulkAddSection", // Second step - target the Bulk Add section
      content: "Here you can select the Bulk Product option. Check this box to add products in bulk.",
      placement: "left", // Tooltip appears below the target
      disableBeacon: true, // Disable beacon for this step
    },
  ]);

  useEffect(() => {
    // Check if the feature was previously closed
    const closed = localStorage.getItem("bulkOrderFeatureClosed");
    if (closed === "true") {
      setIsClosed(true);
    }
  }, []);

  const handleClose = () => {
    // Store the closed state in localStorage
    localStorage.setItem("bulkOrderFeatureClosed", "true");
    setIsClosed(true);
  };

  const clearFileSelection = () => {
    setSelectedFile(null); // Clear the selected file (content of `file`)
    setErrorMsg(''); // Clear the error message
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the file input value
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      setErrorMsg('No file selected');
      return;
    }
    
    setSelectedFile(file);
    setErrorMsg('');
  
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
  
      if (parsedData.length === 0) {
        setErrorMsg('Uploaded file is empty or invalid format');
        return;
      }
  
      const formattedData = parsedData.map(row => ({
        sku: row.sku?.toString().trim(),
        qty: parseInt(row.qty, 10) || 1,
        customPrice: parseFloat(row.custom_price) || null
      })).filter(item => item.sku); // Remove empty rows
  
      if (formattedData.length === 0) {
        setErrorMsg('No valid SKU found in the file');
        return;
      }

      // Restrict to 100 SKUs
      if (formattedData.length > 100) {
        formattedData = formattedData.slice(0, 100);
        setErrorMsg('Only the first 100 SKUs have been processed.');
      }
  
      setBulkData(formattedData);
    };
  
    reader.readAsArrayBuffer(file);
  };

  // if (isClosed) return null;

  return showcart ? (
    <>
      {!quotationId && (
        <>
        {!isClosed && (
          <>
          <Joyride
            steps={steps}
            continuous={true} // Enable continuous steps
            scrollToFirstStep={true}
            run={isJoyrideActive} // Only run if joyride is active
            showSkipButton={true}
            showProgress={true}
            scrollOffset={50}
            locale={{
              back: "Back", // Text for the back button
              next: "Next", // Text for the next button
              skip: "Skip", // Text for the skip button
              last: "Finish", // Text for the skip button
              finish: "Finish", // Change the finish button text to "Finish"
            }}
            callback={({ status, type }) => {

              if ((type === "tour:end" && status === "finished") || status === 'skipped') {
                setIsJoyrideActive(false); // Stop tour once finished
                localStorage.setItem("bulkOrderFeatureClosed", "true");
                setIsClosed(true);
              }
            }}
            styles={{
              options: {
                arrowColor: "#fff",
                backgroundColor: "#fff",
                overlayColor: "rgba(0, 0, 0, 0.7)",
                primaryColor: "#dd202c", // Customize the color
              },
            }}
          />
        <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Col className="newFeatureBloc" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span><b>New Feature:</b> Order with Bulk SKUs is now available! You can now create orders by adding Bulk SKUs quickly. Save time and streamline your ordering process!</span>
            <p
          onClick={handleClose}
          style={{
            margin: 0,
            cursor: "pointer",
            fontWeight: "bold",
            padding: "5px",
          }}
        >
          ✖
        </p>
          </Col>
        </Row>
        </>
        )}  
      <Card>
        
        <CardHeader className="bg-light">
          <Row>
          <Col className="col-md-6">
          <h6>Add a Product</h6>
          </Col>
          <Col className="col-md-6" style={{display: 'flex', justifyContent: 'flex-end'}}>
          <label className="items-center bulkAddSection" style={{display: 'flex', alignItems: 'baseline'}}>
            <input
              type="checkbox"
              // checked={bulkMode}
              onChange={() => setBulkMode(!bulkMode)}
              className="w-4 h-4 me-2 "
            />
            <h6> Add Bulk Product</h6>
          </label>
          </Col>
          </Row>
        </CardHeader>
        <CardBody>
        {bulkMode ? (
              <>
              <AvForm onValidSubmit={addToCartBulkQty}>
              <textarea
                name="skus"
                placeholder="Enter SKUs (one per line, max 100)"
                className="w-full mt-3 p-2 border rounded-lg resize-none h-60"
                style={{ width: "100%", height: 150 }}
                value={skus}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              ></textarea>
              <div style={{ position: 'relative', margin: '20px 0' }}>
                <hr style={{ borderColor: '#ccc' }} />
                <span
                  style={{
                    position: 'absolute',
                    top: '-0.5em',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: 'white',
                    padding: '0 10px',
                    fontWeight: 'bold',
                  }}
                >
                  OR
                </span>
              </div>
              <div className="border-2 border-light text-center p-5 rounded position-relative mb-2" style={{ borderStyle: 'dashed' }}>
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bx-cloud-upload"></i>
                          </div>
                          <h4>Drop .csv or .xlsx files here or click to upload.</h4>
                          <input
                            type="file"
                            name="file"
                            required
                            ref={fileInputRef} // Attach the ref to file input
                            accept=".csv, .xlsx" 
                            // onChange={(e) => {
                            //   const file = e.target.files[0];
                            //   setSelectedFile(file); // Set the selected file
                            //   if (!file) {
                            //     setErrorMsg('No file selected');
                            //   } else {
                            //     setErrorMsg('');
                            //   }
                            // }}
                            onChange={(e) => handleFileUpload(e)}
                            className="opacity-0 w-100 h-100 position-absolute top-0 start-0"
                          />
                        </div>
                        {/* Display the selected file name */}
                          {selectedFile && (
                            <div className="mt-3 mb-2 text-start d-flex align-items-center justify-content-between gap-2 border border-success rounded bg-soft-success px-3 py-2">
                              <p className="m-0 text-success d-flex align-items-center justify-content-center gap-2">
                                <i className="mdi mdi-microsoft-excel text-success fs-24"></i>
                                <strong>Selected file:</strong> {selectedFile.name}
                              </p>
                              <i
                                className="mdi mdi-close-circle text-danger fs-24"
                                onClick={clearFileSelection} // Clear file on click
                                style={{ cursor: 'pointer' }}
                              ></i>
                            </div>
                          )}
                          {/* Error message if any */}
                          {errorMsg && (
                            <p className="text-danger mt-4 p-1 border border-danger rounded text-center">
                              {errorMsg}
                            </p>
                          )}
                        <a href={bulkSkuUploadFile}
                                          download={bulkSkuUploadFile}
                                          className="btn btn-info"
                                        >
                                          <span className="d-flex gap-2 align-items-center">
                                            <i className="bx bx-download"></i>Sample File To Download
                                          </span>
                                        </a>
              <Col  style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button type="submit" className="btn btn-dark mt-4">
                  Add
                </Button>
              </Col>
              </AvForm>
              </>
            ):(
              <>
          <AvForm onValidSubmit={addToCartQty}>
            <Row>
              <Col className="col-md-auto">
                <AvField
                  name="sku"
                  label="SKU*"
                  type="number"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Please Enter SKU.',
                    },
                  }}
                />
              </Col>
              <Col className="col-md-auto">
                <AvField
                  name="qty"
                  label="QtY*"
                  type="number"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Please Enter Qty.',
                    },
                  }}
                />
              </Col>
              <Col className="col-md-auto">
                <Button type="submit" className="btn btn-dark mt-4">
                  Add
                </Button>
              </Col>
             
            </Row>
          </AvForm>
          </>
            )}
        </CardBody>
      </Card>
      </>
    )}

      {cartItemClone?.length > 0 ? (
        <Card>
          <CardHeader>
            <h6>Customer Basket</h6>
          </CardHeader>
          <CardBody className="p-0">
            <div className="border-0">
              <table className="table table-hover mb-0 align-middle">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col"></th>
                    <th scope="col">Product</th>
                    <th scope="col">Price </th>
                    <th scope="col">Custom Price</th>
                    <th scope="col">QTY</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Total</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItemClone?.length > 0 ? (
                    cartItemClone?.map(function (item, index) {
                      if (item?.product?.coreFields?.itemMode === 2) {
                        setShowShippingControll(false);
                      }
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>
                            <img
                              alt=""
                              width="76"
                              height="76"
                              className="img-thumbnail"
                              src={item?.product?.mediaGallery?.[0]?.value}
                            />
                          </td>
                          <td className="product">
                            <p
                              className="m-0 fw-medium"
                              style={{ maxWidth: 230 }}
                            >
                              {item.product.name}{' '}
                              {selectedService ? ` - ${selectedService}` : null}
                            </p>
                            <p className="m-0 text-muted fs-11">
                              SKU : {item.sku}
                            </p>
                            {item?.sku === '990700100004' ? (
                              <p>
                                Service Name :{' '}
                                <SelectPicker
                                  data={serviceOptions}
                                  placeholder="Select an Service Type"
                                  onChange={handleSelectServiceType}
                                  required
                                />
                              </p>
                            ) : null}

                            {item?.product?.coreFields?.itemMode === 2 ? (
                              <p>
                                Item Type :
                                <span className="ps-2 text-danger fw-bold pe-3">
                                  Back to Back
                                </span>{' '}
                                Supplier name :{' '}
                                <span className="ps-2 text-danger fw-bold">
                                  {item?.product?.coreFields?.supplierInfo?.code.replace(
                                    /-/g,
                                    ' '
                                  )}
                                </span>
                              </p>
                            ) : (
                              ''
                            )}
                          </td>
                          <td className="addCustomPrice">
                            <div className="d-flex align-items-center justify-content-start gap-1">
                              {countryInfo?.currency}{' '}
                              {item?.product?.defaultPrice?.specialPrice.toFixed(
                                2
                              ) !=
                              parseFloat(
                                item?.product?.selectedPrice?.specialPrice
                              ).toFixed(2) ? (
                                <>
                                  <p className="text-nowrap m-0 fw-medium">
                                    <span className="CrossText text-danger">
                                      {parseFloat(
                                        item?.product?.defaultPrice
                                          ?.specialPrice
                                      ).toFixed(2)}
                                    </span>
                                  </p>
                                </>
                              ) : (
                                ''
                              )}
                              <p className="text-nowrap m-0 fw-medium">
                                {isNaN(
                                  parseFloat(
                                    item?.product?.selectedPrice?.specialPrice
                                  )
                                )
                                  ? '0.00'
                                  : parseFloat(
                                      item?.product?.selectedPrice?.specialPrice
                                    ).toFixed(2)}
                              </p>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                name="customPriceEnable[]"
                                type="checkbox"
                                onChange={(e) =>
                                  customPriceCheckboxChange(e, item.sku, index)
                                }
                                checked={customPrices[item.sku] !== undefined}
                                id={'check_custom_' + item.sku}
                              />
                              <label
                                className="form-check-label"
                                for={'check_custom_' + item.sku}
                              >
                                Custom Price
                              </label>
                            </div>
                          </td>
                          <td>
                            {item?.product?.coreFields?.itemMode !== 2 ? (
                              <>
                                <input
                                  name="customPrice"
                                  required=""
                                  id={'price_custom_' + item?.sku}
                                  type="text"
                                  value={
                                    item?.product?.selectedPrice?.specialPrice
                                  }
                                  className={`${
                                    !customPrices[item?.sku] &&
                                    customPrices[item?.sku] !== ''
                                      ? 'customPriceTxtClr'
                                      : 'customPriceTxtClrInvert'
                                  } form-control customPrice`}
                                  disabled={
                                    !customPrices[item?.sku] &&
                                    customPrices[item?.sku] !== ''
                                  }
                                  onChange={(e) => ChangeCustomPrice(e, index)}
                                  style={{ maxWidth: 120 }}
                                />
                              </>
                            ) : (
                              ''
                            )}
                          </td>
                          <td>
                          {item?.product?.inventoryStock?.inStock === true ? (
                            <>
                            <input
                              name={`qty-${item?.sku}`}
                              required=""
                              id={`qty-${item?.sku}`}
                              type="text"
                              className="form-control qty"
                              value={item.qty}
                              onChange={(e) => ChangeCustomQty(e, index)}
                              style={{ maxWidth: 70 }}
                            />
                            </>
                          ):(
                            <>
                            <span
                                style={{color: 'white', backgroundColor: 'red', borderRadius: 5, padding: 2}}
                              >
                                Out of Stock
                              </span>
                            </>
                          )}
                            
                          </td>
                          <td>0.00</td>
                          <td>
                            <p className="text-nowrap mb-0 fw-medium">
                              {countryInfo?.currency}{' '}
                              {item?.product?.cart?.subTotal?.toFixed(2)}
                            </p>
                          </td>
                          <td>
                            <div className="d-flex justify-content-end gap-2">
                              <Button
                                color="danger"
                                outline
                                onClick={() => removeItem(item?._id)}
                                className="fs-11 px-2 py-1"
                              >
                                Delete
                              </Button>{' '}
                              {item?.product?.inventoryStock?.inStock === true && (
                              <Button
                                color="success"
                                type="button"
                                onClick={() => {
                                  updateCustomPrice(index);
                                }}
                                className="fs-11 px-2 py-1"
                              >
                                Update
                              </Button>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No items added
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
          <CardFooter>
            {Object.keys(cart).length > 0 ? (
              <div className="d-flex justify-content-end">
                <Button
                  type="submit"
                  color="dark"
                  className="fs-12"
                  onClick={() => {
                    setShowmapandAddress(true);
                  }}
                  disabled={disableNextToAddress}
                >
                  Continue
                </Button>
              </div>
            ) : (
              ''
            )}
          </CardFooter>
        </Card>
      ) : null}
      <Modal
        isOpen={editModal}
        toggle={() => {
            editModalModal();
        }}
        backdrop={'static'}
        id="staticBackdrop"
        centered
        size="md"
      >
        <ModalHeader
          className="modal-title"
          id="rolesAndPermissions"
          toggle={() => {
            editModalModal();
          }}
        >
          Confirm before create order?
        </ModalHeader>
        <ModalBody>
          <div>
            <p className="pb-3 fs-14 text-start m-0 d-flex flex-column">
            Are you sure you want to create order for this quotation? This action cannot be undone.
            </p>
            <div className="d-flex gap-2 justify-content-start align-items-center">
            <AvForm onValidSubmit={addToCartBulkFromQuoteQty}>
            <Button color="success" size="md" disabled={isLoading}>
              {isLoading ? (
                <Spinner size="sm" />  // This is the loader
              ) : (
                'Yes, Create'
              )}
            </Button>
            </AvForm>

              <Button outline color="dark" size="md" onClick={() => editModalModal()}>
                No
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Toaster position="top-right" reverseOrder={false} />
    </>
  ) : (
    ''
  );
}

export default CreateNewOrderComponent;
