import React from "react";

import { MetaTags } from "react-meta-tags";
import {
  DashBreadcrumb,
} from "../../../components/Common/Breadcrumb";
import {
    Button,
    Col,
    Container,
    Row,
  } from "reactstrap";

import ListingQuotation from "./components/Listing/ListingQuotation.component";
import { useHistory } from "react-router-dom";

const Quotations = () => {
  const history = useHistory();
  const title = "Quotations";

  const createQuote = () => {
    history.push(`/orders/quotations/create`);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Quotations</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Quotations" breadcrumbItem="Manage Quotations" />
            </Col>
            <Col xl={6} className="d-flex justify-content-end"><Button style={{height: '70%'}} onClick={() => {
                                    createQuote();
                                }}>Create Quotation</Button></Col>
          </Row>
        </Container>
        <ListingQuotation></ListingQuotation>
      </div>
    </React.Fragment>
  );
};
export default Quotations;
