//import './AddPromotions.scss';
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { SuccessToast, ErrorToast } from '../../../../../utils/Toaster';
import { useDispatch } from 'react-redux';
import { setSalesPersonCode as applySalesPersonCode } from '../../actions/action';
import { setPageLoader } from '../../../../../Redux/order/createOrderSlice';

function AddSalesPersonInfo(props) {
  const { cart = {}, showSalesPersonInfo } = props || {};
  const dispatch = useDispatch();
  const [salesMancode, setSalesMancode] = useState('');

  useEffect(() => {
    setSalesMancode(cart?.omsConfig?.salesManCode || '');
  }, [cart]);

  // Apply or update the sales code
  const handleSalesPersonCode = async (e, v) => {
    e.preventDefault();
    dispatch(setPageLoader(true));

    const response = await applySalesPersonCode({ salesmanCode: v.salesmanCode }, dispatch);

    if (response?.omsConfig?.salesManCode) {
      setSalesMancode(response.omsConfig.salesManCode);
      SuccessToast('Sales person code applied successfully');
    } else {
      ErrorToast('Sales person code not applied');
    }

    dispatch(setPageLoader(false));
  };

  // Remove the salesman code
  const removeSalesPersonCode = async () => {
    dispatch(setPageLoader(true));

    const response = await applySalesPersonCode({ salesmanCode: '' }, dispatch);

    if (response?.omsConfig?.salesManCode === '') {
      setSalesMancode(''); // Clears state and input field
      SuccessToast('Sales person code removed successfully');
    } else {
      ErrorToast('Failed to remove sales person code');
    }

    dispatch(setPageLoader(false));
  };

  return showSalesPersonInfo ? (
    <Card className="promotionsContain">
      <CardHeader className="bg-light">
        <h6>Sales Person Info</h6>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <AvForm onValidSubmit={handleSalesPersonCode} className="position-relative">
              <AvField
                name="salesmanCode"
                label="Sales Person Code"
                type="text"
                value={salesMancode}
              // onChange={(e) => setSalesMancode(e.target.value)}
              />
              {salesMancode ? (
                <>
                  <Button color="success" type="submit" className="mt-3">
                    Update
                  </Button>
                  <Button color="danger" type="button" className="mt-3 ms-2" onClick={removeSalesPersonCode}>
                    Remove
                  </Button>
                </>
              ) : (
                <Button color="success" type="submit" className="mt-3">
                  Apply
                </Button>
              )}
            </AvForm>
          </Col>
        </Row>
      </CardBody>
    </Card>
  ) : null;
}

export default AddSalesPersonInfo;
