import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    exportedReportList: [],
    isLoading: true,
};
const exportedreportSlice = createSlice({
    name: "ExportedReports",
    initialState,
    reducers: {
        setExportedReportList(state, actions) {
            state.exportedReportList = actions.payload;
            state.isLoading = false;
        },

    },
});

export const { setExportedReportList } = exportedreportSlice.actions;

const exportedreportReducer = exportedreportSlice.reducer;

export default exportedreportReducer;
