import axios from 'axios';
import UserAuthKey from '../../../components/Common/UserAuthKey';
import { setUserData } from '../../../Redux/user/userSlice';

import {
  setInvoiceList,
  setInvoiceCreate,
  setInvoiceDetails,
  setInvoiceExport,
} from '../../../Redux/invoice/invoiceSlice';

const apiUrl = process.env.REACT_APP_OMS_API;

export const InvoiceCreate = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/payment-invoice-create`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setInvoiceCreate(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};

export const fetchInvoiceList = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/list-invoices`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setInvoiceList(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    }
  }
};

export const fetchInvoiceDetails = async ({ params }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/get-invoice-details`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    return response?.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      window.location.replace('/logout');
    }
  }
};
