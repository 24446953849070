import { gql } from '@apollo/client';
/**
 * Common Cart Output
 */
const cartOutput = `
  _id
  email
  country
  language
  cartMeta {
    deliveryMode
    couponDiscount
    couponError
    couponDescription
    freebieInfo {
      priceLeftToAddFreebie
      freebiePrice
      isFreebieApplicable
      productSkus {
        sku
        qty
      }
    }
    sellers {
      orderTotal
      shippingAllowed
      sellerId
      supplierWebId
      totalShippingCharge
      installationCharge
      sellerInfo {
        name
      }
    }
    sellerAssemblyInfo
    couponwiseDiscount {
      couponDiscount
      couponDescription
      couponType
      couponRuleId
    }
  }
  coupon {
    code
  }
  paymentMethod {
    code
    charge
  }
  deliveryMethod {
    code
    title
  }
  availableDeliveryMethods {
    isEnabled
    code
    title {
      en
      ar
    }
    instructions{
      en
      ar
    }
    sortOrder
    logo
  }
  availablePaymentMethods {
    isEnabled
    code
    widget
    title {
      en
      ar
    }
    instructions{
      en
      ar
    }
    platform
    minAmount
    maxAmount
    sortOrder
    icon {
      src
      height
      width
    }
    logo {
      src
      position
      height
      width
    }
  }
  items {
    _id
    sellerId
    qty
    sku
    product {
      _id
      name
      categoryLevel1
      categoryLevel2
      categoryLevel3
      coreFields {
        itemMode
        material
        country_of_manufacture
        warranty
        color
        brand
        cbm
        fixing_time
        weight
        warranty
        installation_type
        budget_category
        delivery_method
        url_key
        assembly_required
        assembly_provided
        supplierInfo {
          code
          id
          sku
          supplierId
        }
      }
      selectedPrice {
        specialPrice
        currency
        country
      }
      defaultPrice {
        specialPrice
        currency
        country
      }
      inventoryStock {
        qty
        country
        inStock
      }
      mediaGallery {
        media_type
        value
        disabled
        sliderPosition
      }
      cart {
        subTotal
        sku
        qty
        grandTotal
        discountProportional
        discountApplied
        discardSubsequent
        isFreeItem
        groupBy {
          name
          id
          canAddMore
          addMoreFrom
          ruleType
          comboPrice{
            specialPrice
          }
          media{
            value
          }
        }
      }
    }
  }
  totalItems
  grandTotal
  discountApplied
  subTotal
  orderInfo {
    reference
    createdDate
  }
  countryInfo {
    name
    code
    flag
    currency
  }
  shippingAddress {
    _id
    firstName
    lastName
    telephone
    alternateTelephone
    address1
    address2
    city
    region
    country
    latitude
    longitude
    countryCode
    mapData
    addressType
    flatNo
    plusCode,
    isUsedBilling,
    companyName,
    trn
  }
  creditNote {
    number
    amount
    storeNo
    validUpTo
    redeemedAmount
  }
  giftCard {
    giftVoucherNumber
    currentBalance
    redeemedAmount
    validUpTo
    gvType
  }
  wallet {
    walletId
    currentBalance
    redeemedAmount
    isApplied
  }
  orderCanPlace
  orderCantPlaceReason
  minimumCartValue
  minimumShippingFreeValue
  shipment
  isGuest
  customerId
  createdAt
  updatedAt
  omsConfig
`;
/**
 *  VIEW CART
 */
export const VIEW_CART = gql`
  query VIEW_CART($cartToken: String) {
    viewCart(cartToken: $cartToken) {
      ${cartOutput}
    }
  }
`;

/**
 * ADD OR UPDATE ITEM
 */
export const ADD_OR_UPDATE_ITEM_TO_CART = gql`
  mutation ADD_OR_UPDATE_ITEM_TO__CART($input: AddToCartInput!) {
    addToCart(input: $input) {
      success
      message
      cart {
        ${cartOutput}
      }
    }
  }
`;

/**
 * ADD OR UPDATE BULK ITEM
 */
export const ADD_OR_UPDATE_BULK_ITEM_TO_CART = gql`
  mutation ADD_OR_UPDATE_BULK_ITEM_TO_CART($input: AddToCartInput!) {
    bulkAddToCartFormOms(input: $input) {
      success
      message
      cart {
        ${cartOutput}
      }
    }
  }
`;

/**
 *  REMOVE CART
 */
export const REMOVE_ITEM_FROM_CART = gql`
  mutation REMOVE_ITEM_FROM_CART($cartToken: String, $itemId: String!) {
    removeFromCart(cartToken: $cartToken, itemId: $itemId) {
      ${cartOutput}
    }
  }
`;

/**
 *  SET SHIPPING ADDRESS TO  CART
 */
export const SET_SHIPPING_ADDRESS_TO_CART = gql`
  mutation SET_SHIPPING_ADDRESS_TO_CART($input: AddShippingAddressInput!) {
    setShippingAddress(input: $input) {
      ${cartOutput}
    }
  }
`;

/**
 *  SET EMAIL TO GUEST CART
 */
export const SET_EMAIL_TO_GUEST_CART = gql`
  query SET_EMAIL_TO_GUEST_CART($cartToken: String!, $email: String!) {
    setEmailToGuestCart(cartToken:$cartToken, email: $email) {
      ${cartOutput}
    }
  }
`;

/**
 *  CALCULATE SHIPMENTS
 */
export const CALCULATE_SHIPMENTS = gql`
  query CALCULATE_SHIPMENTS($cartToken: String) {
    calculateShipments(cartToken: $cartToken) {
      ${cartOutput}
    }
  }
`;

/**
 * REMOVE A COUPON FROM CART
 */
export const REMOVE_APPLIED_COUPON = gql`
  query REMOVE_APPLIED_COUPON($cartToken: String) {
    removeCoupon(cartToken:$cartToken)  {
      success
      message
      cart {
        ${cartOutput}
      }
    }
  }
`;

/**
 * REMOVE A COUPON FROM CART
 */
export const APPLY_COUPON = gql`
  mutation APPLY_COUPON($input: CouponInput!) {
    applyCoupon(input:$input)  {
      success
      message
      cart {
        ${cartOutput}
      }
    }
  }
`;

/**
 *  SET PAYMENT METHOD CART
 */
export const SET_PAYMENT_METHOD_TO_CART = gql`
  mutation SET_PAYMENT_METHOD_TO_CART($cartToken: String, $code: String!) {
    setPaymentMethodToCart(cartToken: $cartToken, paymentMethodCode: $code) {
      ${cartOutput}
    }
  }
`;

/**
 * SET SUPPLIER IDS FOR ASSEMBLY CHARGES
 */
export const SET_SUPPLIER_ID_FOR_ASSEMBLY_CHARGES = gql`
  mutation SET_SUPPLIER_ID_FOR_ASSEMBLY_CHARGES($input: AssemblySupplierIdInput!) {
    setAssemblySupplierId(input:$input)  {
      ${cartOutput}
    }
  }
`;

/**
 * VALIDATE GIFT CARD NUMBER
 */
export const VALIDATE_GIFT_CARD = gql`
  query VALIDATE_GIFT_CARD($input: ValidateGiftVoucherInput!) {
    validateGiftvoucher(input: $input) {
      giftVoucherNumber
      amount
      currentBalance
      validUpTo
      gvType
    }
  }
`;

/**
 * VALIDATE GIFT CARD NUMBER PIN
 */
export const VALIDATE_GIFT_CARD_PIN = gql`
  query VALIDATE_GIFT_CARD_PIN($input: ValidateGiftVoucherPinInput!) {
    validateGiftvoucherPin(input: $input) {
      giftVoucherNumber
    }
  }
`;

/**
 * SET CARD INFORMATION
 */
export const SET_GIFT_CARD_INFORMATION = gql`
  mutation SET_GIFT_CARD_INFORMATION($input: GiftCardInputs!) {
    setGiftCardInformation(input: $input) {
      ${cartOutput}
    }
  }
`;

/**
 * SET CREDITNOTE INFORMATION
 */
export const SET_CREDIT_NOTE_INFORMATION = gql`
mutation SET_CREDIT_NOTE_INFORMATION($input: CreditNoteInput!) {
  setCreditNoteInformation(input: $input) {
    ${cartOutput}
  }
}
`;
/**
 * SET CARD INFORMATION
 */
export const SET_SHIPMENT_TIME_SLOT = gql`
  mutation SET_SHIPMENT_TIME_SLOT($input: ShipmentSlotSetInputs!) {
    setShipmentSlot(input: $input) {
      ${cartOutput}
    }
  }
`;
export const SET_WALLET_INFORMATION = gql`
  mutation SET_WALLET_INFORMATION($input: WalletInputs!) {
    setWalletInformation(input: $input) {
      ${cartOutput}
    }
  }
`;

export const SET_SALESMAN_CODE = gql`
  query SET_SALESMAN_CODE($data: JSONObject, $cartToken: String!) {
    updateOmsConfig(data: $data, cartToken: $cartToken) {
      ${cartOutput}
    }
  }
`;