import React from 'react';
import { Redirect } from 'react-router-dom';

// Orders Dashboard
import MainOrdersDashboard from '../pages/OrdersDashboard';

//Dashboard
import Dashboard from '../pages/Dashboard/index';
import Dashboardd from '../pages/Dashboardd/index';
import OrderDashboard from '../pages/Orders/components/dashboard';

//Orders

import Orders from '../pages/Orders/index';
import OrderDetails from '../pages/Orders/details';
import DeleteOrderDetails from '../pages/Orders/components/OrderDeleteDetail';
import DeleteOrders from '../pages/Orders/components/DeleteIndex';
import cncOrders from '../pages/Orders/ManageCncOrderindex';
import cncDetails from '../pages/Orders/ManageCncOrderDetail';
import ReturnOrders from '../pages/Orders/ReturnOrderindex';
import ReturnApprovedOrders from '../pages/Orders/ReturnApprovedOrderindex';
import ReturnMarketplace from '../pages/Orders/components/return/ReturnMarketplaceindex';

import ReturnDetails from '../pages/Orders/ReturnDetails';
import ReturnMarketDetails from '../pages/Orders/ReturnMarketDetails';

import splitOrders from '../pages/Orders/splitorderindex';
import ReviewRatingDetail from '../pages/Reviews/ReviewDetail';
import b2bOrders from '../pages/Orders/b2bindex';
import installOrders from '../pages/Orders/b2bindex';
import bulkOrders from '../pages/Orders/ManageBulkOrderIndex';
import bulkDetails from '../pages/Orders/bulkDetails';
import marketplaceOrders from '../pages/MarketPlace/MarketplaceOrderindex';
import marketplaceSplitOrders from '../pages/MarketPlace/MarketplaceSplitOrderindex';

import MarketDetail from '../pages/MarketPlace/MarketDetail';
import MarketSplitDetail from '../pages/MarketPlace/MarketSplitDetail';

import ImportOrders from '../pages/MarketPlace/ImportOrder';
import ImportReturnOrders from '../pages/MarketPlace/ImportReturnOrder';

import MailSettings from '../pages/Settings/MailsettingsForm';
import TemplateSettings from '../pages/Settings/TemplateSettings';
import ManageSettings from '../pages/Settings/ManageSettingsIndex';
import DeliverySettings from '../pages/Settings/DeliverySettings';
import CustomerTracking from '../pages/bccwOrders/CustomerTrackingIndex';
import Photoshoot from '../pages/PhotoShoot/ManageProductShootIndex';
import PhotoDetails from '../pages/PhotoShoot/Photodetail';
import MarketplaceDashboard from '../pages/MarketPlace/components/dashboard';
import TrackingDetails from '../pages/bccwOrders/TrackingDetail';
import NonServicesAreaForm from '../pages/Orders/components/NonPlanningorders/NonServiceAreaForm';
import AddCustomerForm from '../pages/Customers/AddCustomerForm';
import AddCustomerFormDetail from '../pages/Customers/AddCustomerFormDetail';
import AddCustomerEditForm from '../pages/Customers/EditAddressForm';
import UserForm from '../pages/Permissions/components/UserForm';
import EditUserForm from '../pages/Permissions/components/EditUserForm';
import KnowledgeBaseCategory from '../pages/Categories';
import AddNewCategoryForm from '../pages/Categories/components/AddNewCategory';
import KnowledgeBaseArticles from '../pages/Articles';
import AddNewArticle from '../pages/Articles/components/AddNewArticles';
import KbUpdateCategoryForm from '../pages/Categories/components/KBCategoryUpdateForm';
import EditNewArticle from '../pages/Articles/components/EditArticleDetail';
import KBDepartments from '../pages/KbDepartments';
import ImportExport from '../pages/ImportExport';
import LsStockSkuList from '../pages/ImportExport/LsStockSkuIndex';

import DeliveryReport from '../pages/Orders/DeliveryReport';
import DeliveryReportUpload from '../pages/Orders/DeliveryReport/import';
import QaOrders from '../pages/Orders/QaOrders';

import Quotations from '../pages/Orders/Quotations';

import OrderCreate from '../pages/Orders/Cart';
//returns
import Returns from '../pages/Returns/index';
//import ReturnDetails from "../pages/Returns/details";
import CreateFormReturn from '../pages/Orders/components/return/CreateNewReturn';
import CreateFormReturnWarehouse from '../pages/Orders/components/return/CreateNewReturnWarehouse';

//Calendar
import Calendar from '../pages/Calendar/index';

import Permissions from '../pages/Permissions/index';
import UserRoleList from '../pages/Permissions/User';

import Callback from '../pages/Callback/CallbackIndex';
import Callbackdetail from '../pages/Callback/Callbackdetail';

import ManageRiverHillOrders from '../pages/Orders/ManageRiverHillOrderIndex';
import RiverHillOrderDetail from '../pages/Orders/ManageRiverHillOrderDetail';
import DesignYourHomeLead from '../pages/Orders/DesignYourHomeLeadIndex';
import HomeLeadDetails from '../pages/Orders/DesignYourHomeLeadDetail';

// Authentication related pages
import userProfile from '../pages/Authentication/user-profile';
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import ForgetPwd from '../pages/Authentication/ForgetPassword';

import NonPlanningOrder from '../pages/Orders/ManageNonPlanningOrderIndex';
import NonServiceArea from '../pages/Orders/ManageNonServiceAreaIndex';
import ManageRefunds from '../pages/refund/ManageRefundIndex';
import Queue from '../pages/Queuess/ManageQueueIndex';
import RejectedQueue from '../pages/Queuess/ManageQueueRejectedIndex';

import QueueReturn from '../pages/Queuess/ManageQueueReturnIndex';

import ApprovalRefund from '../pages/Queuess/ManageRefundApproval';

import ContactsProfile from '../pages/Contacts/ContactsProfile/contacts-profile';
import Reports from '../pages/reports';
import NewRoleForm from '../pages/Permissions/components/NewRoleForm';
import EditNewRole from '../pages/Permissions/components/EditNewRole';
import StoreConfig from '../pages/Orders/StoreConfigIndex';
import AllCustomer from '../pages/Customers/AllCustomersIndex';
import ClickandCollectList from '../pages/Permissions/ClickandCollectList';
import ClickandCollect from '../pages/Permissions/ClickandCollect';
import ClickandCollectForm from '../pages/Permissions/ClickandCollectForm';
import Reviews from '../pages/Reviews/ReviewsIndex';
import OrderLogReport from '../pages/Orders/components/LogFile';
import ProductsFeedback from '../pages/Products/ProductsFeedBackIndex';
import ProductFeedbackDetail from '../pages/Products/ProductsFeedBackDetails';
import AddNewDepartment from '../pages/KbDepartments/components/AddDepartment';
import EditDepartment from '../pages/KbDepartments/components/EditDepartment';
import NotificationSettings from '../pages/Settings/Notification/index';
import ImageTools from '../pages/Tools/ImageTools';
import ManageInvoices from '../pages/Invoices';
import ImportInstallationOrders from '../pages/MarketPlace/ImportInstallationOrder';
import installationorders from '../pages/Orders/ManageInstallationindex';

import Wallet from '../pages/Wallet';
import ExportedReports from '../pages/ExportedReports/ExportedReportIndex';
import CreateQuotation from '../pages/Orders/Quotations/create';

const userRoutes = [
  //dashboard
  { path: '/orders-dasboard', component: Dashboard },
  { path: '/dashboardd', component: Dashboardd },

  //orders
  { path: '/orderdashboard', component: OrderDashboard },
  { path: '/orders', component: Orders },
  { path: '/orders/create', component: OrderCreate },
  { path: '/orders/deliveryReport', component: DeliveryReport },
  { path: '/orders/deliveryReport/import', component: DeliveryReportUpload },
  { path: '/orders/qa', component: QaOrders },
  { path: '/orders/quotations', component: Quotations },
  { path: '/orders/quotations/create', component: CreateQuotation },

  { path: '/orders/:id', component: OrderDetails },
  { path: '/DeleteOrderDetails/:id', component: DeleteOrderDetails },
  { path: '/splitorders', component: splitOrders },
  { path: '/b2borders', component: b2bOrders },
  { path: '/installorders', component: b2bOrders },

  { path: '/bulkorders', component: bulkOrders },
  { path: '/bulkorders/:id', component: bulkDetails },
  { path: '/cncorders', component: cncOrders },
  { path: '/cncorders', component: cncDetails },
  { path: '/returnorders', component: ReturnOrders },
  { path: '/returnapprovedOrders', component: ReturnApprovedOrders },
  { path: '/returnmarketplace', component: ReturnMarketplace },

  { path: '/returnorders/:id/:order', component: ReturnDetails },
  { path: '/returnmarketorders/:id/:order', component: ReturnMarketDetails },

  { path: '/OrderLogReport/:ref', component: OrderLogReport },
  { path: '/DeleteOrders', component: DeleteOrders },

  // MarketPlace
  { path: '/marketplaceorders', component: marketplaceOrders },
  { path: '/marketplacesplitorders', component: marketplaceSplitOrders },

  { path: '/marketplaceorders/:id', component: MarketDetail },
  { path: '/marketplacesplitorders/:id', component: MarketSplitDetail },

  { path: '/importorders', component: ImportOrders },
  { path: '/importreturnorders', component: ImportReturnOrders },

  { path: '/marketPlacedashboard', component: MarketplaceDashboard },

  //returns
  { path: '/returns', component: Returns },
  { path: '/CreateFormReturn', component: CreateFormReturn },
  { path: '/CreateFormReturnWarehouse', component: CreateFormReturnWarehouse },

  //profile
  { path: '/profile', component: userProfile },

  //dashboard
  { path: '/calendar', component: Calendar },

  { path: '/permission', component: Permissions },
  { path: '/UserForm', component: UserForm },
  { path: '/EditUserForm/:id', component: EditUserForm },
  { path: '/userRoleList', component: UserRoleList },
  { path: '/NewRoleForm', component: NewRoleForm },
  { path: '/EditNewRole/:id', component: EditNewRole },
  //Contact
  { path: '/contacts-profile', component: ContactsProfile },

  // Settings
  { path: '/mailSettings', component: MailSettings },
  { path: '/templateSettings', component: TemplateSettings },

  { path: '/ManageSettings', component: ManageSettings },
  { path: '/DeliverySettings/:id/:country', component: DeliverySettings },
  // Reivews
  { path: '/deliveryreviews', component: Reviews },
  { path: '/deliveryreviewsdetail/:ref', component: ReviewRatingDetail },

  //  callback
  { path: '/callback', component: Callback },
  { path: '/Callbackdetail/:id', component: Callbackdetail },

  // Manage Photoshoot
  { path: '/photoShoot', component: Photoshoot },
  { path: '/photoshootdetail/:id', component: PhotoDetails },

  // BCCW Orders
  { path: '/ManageRiverHillOrders', component: ManageRiverHillOrders },
  { path: '/RiverOrderDetails/:id', component: RiverHillOrderDetail },
  { path: '/CustomerTracking', component: CustomerTracking },
  { path: '/TrackingDetails/:id', component: TrackingDetails },

  { path: '/DesignYourHomeLead', component: DesignYourHomeLead },
  { path: '/HomeLeadDetails/:id', component: HomeLeadDetails },

  // Manage Refund
  { path: '/ManageRefund', component: ManageRefunds },

  //Manage Queue
  { path: '/queue', component: Queue },
  { path: '/rejected-queue', component: RejectedQueue },

  { path: '/queue-return', component: QueueReturn },

  { path: '/ApprovalRefund1', component: ApprovalRefund },

  { path: '/importinstallationorders', component: ImportInstallationOrders },
  { path: '/installationorders', component: installationorders },

  //Customers
  { path: '/AllCustomer', component: AllCustomer },
  { path: '/AddCustomerForm', component: AddCustomerForm },
  { path: '/AddCustomerFormDetail/:id', component: AddCustomerFormDetail },
  { path: '/wallet-list', component: Wallet },
  // { path: '/wallet-list/:id', component: WalletDetails },
  {
    path: '/AddCustomerFormDetail/:uuid/:id',
    component: AddCustomerFormDetail,
  },

  // Manage Reports
  { path: '/reports', component: Reports },

  //  Non Planning Order
  { path: '/NonPlanningOrder', component: NonPlanningOrder },
  { path: '/NonServiceArea', component: NonServiceArea },
  { path: '/NonServicesAreaForm/:id', component: NonServicesAreaForm },
  { path: '/ClickandCollectList', component: ClickandCollectList },
  { path: '/ClickandCollect', component: ClickandCollect },
  { path: '/ClickandCollectForm/:id', component: ClickandCollectForm },
  { path: '/StoreConfig', component: StoreConfig },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },

  // Product Feedback
  { path: '/ProductsFeedback', component: ProductsFeedback },
  { path: '/productFeedbackDetail/:id', component: ProductFeedbackDetail },
  // knowledge Base
  { path: '/Categories', component: KnowledgeBaseCategory },
  { path: '/AddNewCategoryForm', component: AddNewCategoryForm },
  { path: '/Articles', component: KnowledgeBaseArticles },
  { path: '/AddNewArticle', component: AddNewArticle },
  { path: '/EditNewArticle/:id', component: EditNewArticle },
  { path: '/KbUpdateCategoryForm/:id', component: KbUpdateCategoryForm },
  { path: '/KBDepartments', component: KBDepartments },
  { path: '/AddNewDepartment', component: AddNewDepartment },
  { path: '/EditDepartment/:id', component: EditDepartment },
  //  Import Export
  { path: '/ImportExport', component: ImportExport },
  { path: '/LsStockSkuList', component: LsStockSkuList },
  //Sms Settings Module
  { path: '/settings/notification', component: NotificationSettings },
  //Image Tools Module
  { path: '/tools/image-resize', component: ImageTools },

  // Orders Dashboard
  { path: '/dashboard', component: MainOrdersDashboard },
  //Manage Invoices
  { path: '/manage-invoices', component: ManageInvoices },
  { path: '/exported-reports', component: ExportedReports },
];

const authRoutes = [
  //authencation page
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', component: Register },
];

export { userRoutes, authRoutes };
