import React, { useEffect, useState, useCallback } from 'react';
import './CreateQuote.scss';
import 'react-drawer/lib/react-drawer.css';
import {
  Table,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  Label,
  Collapse,
  Button,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import classnames from 'classnames';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import UserAuthKey from "../../../../../components/Common/UserAuthKey";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

const CreateQuote = ({ direction, ...args }) => {

  const omsServiceapiUrl = process.env.REACT_APP_OMS_NEST_API;
  const bearerToken = UserAuthKey();
  let userEmail= ''; 
  if (localStorage.getItem("authUser")) {
    const userObj = JSON.parse(localStorage.getItem("authUser"));
    if (userObj?.role_id !== undefined) {
      userEmail = userObj?.user_email;
    }
  }

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [status, setStatus] = useState('0');
  const [printFile, setPrintFile] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const [isVatIncluded, setIsVatIncluded] = useState(true);

  // Accordion Flush
  const [general, setGeneral] = useState(true);
  const [productList, setProductList] = useState([]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

    

  const [touched, setTouched] = useState({
    productName: false,
    sku: false,
    qty: false,
    uom: false,
    unitPrice: false,
});

const query = useQuery();
const projectId = query.get('project_id');
let quotationId = query.get('quotation_id');
const edit_quotation_id = query.get('edit_quotation_id');

if(edit_quotation_id){
    quotationId = edit_quotation_id;
}else{
    quotationId = query.get('quotation_id');
}


const initialFormData = {
    productName: '',
    sku: '',
    qty: '',
    uom: '',
    unitPrice: '',
    totalValue: '',
};

const initialCustomerFormData = {
    projectId: '',
    customerName: '',
    phoneNo: '',
    emailId: '',
    address: '',
    trnNo: '',
    paymentType: ''
};

const [formData, setFormData] = useState(initialFormData);
const [formCustomerData, setFormCustomerData] = useState(initialCustomerFormData);

// fetch data from the api
const fetchQuoteData = useCallback(async (quotationId) => {

    setIsLoading(true);

    try {

        const payload = {
            quotationId: quotationId,
        };
    
          const response = await axios.post(`${omsServiceapiUrl}/quotations/fetch-single-quote`, payload);

            const { quotation, products } = response.data || {};

            const formattedProducts = products.map(product => ({
                productName: product?.product_name, // Mapping product_name to productName
                sku: product?.sku,
                qty: product?.qty,
                uom: product?.uom,
                unitPrice: product?.unit_price,
                totalValue: product.qty * product.unit_price, // Calculate totalValue
            }));

            setProductList(formattedProducts || []);
            setIsVatIncluded(quotation?.vat_included === 'true' ? 'true' : false)

            setFormCustomerData({
                    projectId: quotation?.project_id || '',
                    customerName: quotation?.customer_name || '',
                    phoneNo: quotation?.mobile || '',
                    emailId: quotation?.email || '',
                    address: quotation?.address || '',
                    trnNo: quotation?.trn_no || '',
                    paymentType: quotation?.payment_type || '',
                });

    setStatus(quotation?.status);
    setPrintFile(quotation?.file || false);

    } catch (error) {
    console.error('Failed to fetch product:', error);
    }

    setTimeout(() => {
    setIsLoading(false);
    }, 400);
}, []);

useEffect(() => {
    if (quotationId) {
        fetchQuoteData(quotationId);
    }
}, [quotationId, fetchQuoteData]);



const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
        let updatedData = { ...prevFormData, [name]: value };

        // Update totalValue if qty or unitPrice is changed
        if (name === "qty" || name === "unitPrice") {
            const qty = parseFloat(updatedData.qty) || 0;
            const unitPrice = parseFloat(updatedData.unitPrice) || 0
            updatedData.totalValue = (qty * unitPrice).toFixed(2);
        }

        return updatedData;
    });
};    

const handleInputCustomerChange = (e) => {
    const { name, value } = e.target; // Extracting name and value from input field
    setFormCustomerData((prevFormData) => ({
        ...prevFormData,
        [name]: value, // Updating the specific field dynamically
    }));
};

const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });
};

const validateForm = () => {
    let errors = {};

    // Check required fields (add your required fields here)
    if (!formData.productName) errors.productName = "Product Name is required";
    if (!formData.sku) errors.sku = "SKU is required";
    if (!formData.qty) errors.qty = "Qty is required";
    if (!formData.unitPrice) errors.unitPrice = "Unit Price is required";
    if (!formData.customerName) errors.customerName = "Customer Name / Company Name is required";
    if (!formData.address) errors.address = "Address is required";
    if (!formData.trnNo) errors.trnNo = "TRN No. is required";

    return errors;
};

const handleEditProduct = (index) => {
    const productToEdit = productList[index];
    setFormData({
        productName: productToEdit.productName,
        sku: productToEdit.sku,
        qty: productToEdit.qty,
        uom: productToEdit.uom,
        unitPrice: productToEdit.unitPrice,
        totalValue: productToEdit.totalValue,
    });
    setIsEditing(true);
    setEditingIndex(index);
};

const handleUpdateProduct = () => {
    const updatedProduct = {
        productName: formData.productName,
        sku: formData.sku,
        qty: formData.qty,
        uom: formData.uom,
        unitPrice: formData.unitPrice,
        totalValue: formData.qty * formData.unitPrice,  // Calculate totalValue
    };
    const updatedList = [...productList];
    updatedList[editingIndex] = updatedProduct;
    setProductList(updatedList);
    setFormData({ productName: '', sku: '', qty: '', uom: '', unitPrice: '', totalValue: '' });
    setIsEditing(false); // Reset the editing state
    setEditingIndex(null); // Clear the editing index
};

const handleAddProduct = () => {
    if (!formData.productName || !formData.sku | !formData.uom || !formData.qty || !formData.unitPrice) {
        setTouched((prev) => ({
            ...prev,
            productName: true,
            sku: true,
            qty: true,
            uom: true,
            unitPrice: true
        }));
        return;
    }
    setProductList([...productList, formData]);
    setFormData({ productName: "", sku: "", uom: "", qty: "", unitPrice: "" });
};

const handleRemoveProduct = (index) => {
    const updatedList = productList.filter((_, i) => i !== index);
    setProductList(updatedList);
};

const handleCheckboxChange = () => {
    setIsVatIncluded(!isVatIncluded); // Toggle VAT inclusion
};

const subtotal = productList.reduce((total, product) => total + Number(product.totalValue), 0);
const vatAmount = subtotal * 0.05; 
const grandTotal = isVatIncluded ? subtotal : subtotal + vatAmount; // Only calculate VAT if the checkbox is unchecked

const handleSubmit = async () => {
    setIsLoading(true);

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
        setTouched({
            productName: !formData.productName,
            sku: !formData.sku,
            qty: !formData.qty,
            unitPrice: !formData.unitPrice,
            customerName: !formCustomerData.customerName,
            phoneNo: !formCustomerData.phoneNo,
            address: !formCustomerData.address,
            trnNo: !formCustomerData.trnNo,
            paymentType: !formCustomerData.paymentType,
        });
        
    }

    // Create a FormData object
    const formDataToSubmit = new FormData();

    // Append form data, avoiding empty values
    Object.entries(formData).forEach(([key, value]) => {
        if (value) {
            formDataToSubmit.append(key, value);
        }
    });

    Object.entries(formCustomerData).forEach(([key, value]) => {
        if (value) {
            formDataToSubmit.append(key, value);
        }
    });

    // Append additional data
    formDataToSubmit.append('productList', JSON.stringify(productList));
    formDataToSubmit.append('subtotal', subtotal);
    formDataToSubmit.append('vatIncluded', isVatIncluded);
    formDataToSubmit.append('vatAmount', vatAmount);
    formDataToSubmit.append('grandTotal', grandTotal);
    formDataToSubmit.append('quotationId', quotationId ? quotationId : '');
    formDataToSubmit.append('userEmail', userEmail ? userEmail : '');

    // // Perform the API call
    try {
        const apiUrl = `${omsServiceapiUrl}/quotations/save`;

        const config = {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
        };

        const response = await axios.post(apiUrl, formDataToSubmit, config);
        const { success = false, message = 'Quotation saved successfully...' } = response?.data || {};
    
        if (success) {
            toast.success(message, {
                style: {
                    border: '1px solid #fff',
                    padding: '20px',
                    color: '#ffffff',
                    background: '#4CAF50',
                  },
                  iconTheme: {
                    primary: '#FFFAEE',
                    secondary: '#4CAF50',
                  },
              });
            setFormData(initialFormData); // Reset form data
            history.push("/orders/quotations");
        } else {
            // alertError(new Error(message));
            toast.error(message, {
                style: {
                  border: '1px solid #fff',
                  padding: '20px',
                  color: '#ffffff',
                  background: '#f44336',
                },
                iconTheme: {
                  primary: '#FFFAEE',
                  secondary: '#f44336',
                },
              });
        }

    } catch (error) {
        console.error('Error creating quotation:', error);
    } finally {
        setIsLoading(false); // Reset loading state regardless of success or failure
    }
};

const handleUpdate = async () => {
    setIsLoading(true); // Set loading state to true

    // Validate the form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
        setTouched({
            productName: !formData.productName,
            sku: !formData.sku,
            qty: !formData.qty,
            unitPrice: !formData.unitPrice,
            customerName: !formCustomerData.customerName,
            phoneNo: !formCustomerData.phoneNo,
            address: !formCustomerData.address,
            trnNo: !formCustomerData.trnNo,
            paymentType: !formCustomerData.paymentType,
        });
        
    }

    // Create a FormData object
    const formDataToSubmit = new FormData();

    // Append form data, avoiding empty values
    Object.entries(formData).forEach(([key, value]) => {
        if (value) {
            formDataToSubmit.append(key, value);
        }
    });

    Object.entries(formCustomerData).forEach(([key, value]) => {
        if (value) {
            formDataToSubmit.append(key, value);
        }
    });

    // Append additional data
    formDataToSubmit.append('productList', JSON.stringify(productList));
    formDataToSubmit.append('subtotal', subtotal);
    formDataToSubmit.append('vatIncluded', isVatIncluded);
    formDataToSubmit.append('vatAmount', vatAmount);
    formDataToSubmit.append('grandTotal', grandTotal);
    formDataToSubmit.append('quotationId', quotationId ? quotationId : '');
    formDataToSubmit.append('userEmail', userEmail ? userEmail : '');

    //Perform the API call
    try {
        const apiUrl = `${omsServiceapiUrl}/quotations/update`;

        const config = {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
        };

        const response = await axios.post(apiUrl, formDataToSubmit, config);
        const { success = false, message = 'Quotation updated successfully...' } = response?.data || {};

        console.log(response);
        
        if (success) {
            toast.success(message, {
                style: {
                    border: '1px solid #fff',
                    padding: '20px',
                    color: '#ffffff',
                    background: '#4CAF50',
                  },
                  iconTheme: {
                    primary: '#FFFAEE',
                    secondary: '#4CAF50',
                  },
              });
            setFormData(initialFormData); // Reset form data
            history.push("/orders/quotations");

        } else {
            toast.error(message, {
                style: {
                  border: '1px solid #fff',
                  padding: '20px',
                  color: '#ffffff',
                  background: '#f44336',
                },
                iconTheme: {
                  primary: '#FFFAEE',
                  secondary: '#f44336',
                },
              });
        }

    } catch (error) {
        console.error('Error updating quotation:', error);
        toast.error(error, {
            style: {
              border: '1px solid #fff',
              padding: '20px',
              color: '#ffffff',
              background: '#f44336',
            },
            iconTheme: {
              primary: '#FFFAEE',
              secondary: '#f44336',
            },
          });
    } finally {
        setIsLoading(false); // Reset loading state regardless of success or failure
    }
};

const handleCreate = async () => {
    setIsLoading(true);

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
        setTouched({
            productName: !formData.productName,
            sku: !formData.sku,
            qty: !formData.qty,
            unitPrice: !formData.unitPrice,
            customerName: !formCustomerData.customerName,
            phoneNo: !formCustomerData.phoneNo,
            address: !formCustomerData.address,
            trnNo: !formCustomerData.trnNo,
        });
        
    }

    // Create a FormData object
    const formDataToSubmit = new FormData();

    // Append form data, avoiding empty values
    Object.entries(formData).forEach(([key, value]) => {
        if (value) {
            formDataToSubmit.append(key, value);
        }
    });

    Object.entries(formCustomerData).forEach(([key, value]) => {
        if (value) {
            formDataToSubmit.append(key, value);
        }
    });

    // Append additional data
    formDataToSubmit.append('productList', JSON.stringify(productList));
    formDataToSubmit.append('subtotal', subtotal);
    formDataToSubmit.append('vatIncluded', isVatIncluded);
    formDataToSubmit.append('vatAmount', vatAmount);
    formDataToSubmit.append('grandTotal', grandTotal);
    formDataToSubmit.append('quotationId', quotationId ? quotationId : '');
    formDataToSubmit.append('userEmail', userEmail ? userEmail : '');

    // // Perform the API call
    try {
        const apiUrl = `${omsServiceapiUrl}/quotations/create`;

        const config = {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
        };

        const response = await axios.post(apiUrl, formDataToSubmit, config);
        const {
            success = false,
            url,
            message,
            quotationId,
          } = response?.data || {};
    
          if (success === true) {
            toast.success(message, {
                style: {
                    border: '1px solid #fff',
                    padding: '20px',
                    color: '#ffffff',
                    background: '#4CAF50',
                  },
                  iconTheme: {
                    primary: '#FFFAEE',
                    secondary: '#4CAF50',
                  },
              });

            history.push(`/orders/quotations/create?quotation_id=${quotationId}`);

            setTimeout(() => {
                window.location.reload();
            }, 2000);

          } else {
            toast.error(message, {
                style: {
                  border: '1px solid #fff',
                  padding: '20px',
                  color: '#ffffff',
                  background: '#f44336',
                },
                iconTheme: {
                  primary: '#FFFAEE',
                  secondary: '#f44336',
                },
              });
          }

    } catch (error) {
        console.error('Error creating quotation:', error);
        toast.error(error, {
            style: {
              border: '1px solid #fff',
              padding: '20px',
              color: '#ffffff',
              background: '#f44336',
            },
            iconTheme: {
              primary: '#FFFAEE',
              secondary: '#f44336',
            },
          });
        // Handle error (e.g., show error message)
    } finally {
        setIsLoading(false); // Reset loading state regardless of success or failure
    }
};

const handleEdit = async () => {
    setIsLoading(true);

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
        setTouched({
            productName: !formData.productName,
            sku: !formData.sku,
            qty: !formData.qty,
            unitPrice: !formData.unitPrice,
            customerName: !formCustomerData.customerName,
            phoneNo: !formCustomerData.phoneNo,
            address: !formCustomerData.address,
            trnNo: !formCustomerData.trnNo,
        });
        
    }

    // Create a FormData object
    const formDataToSubmit = new FormData();

    // Append form data, avoiding empty values
    Object.entries(formData).forEach(([key, value]) => {
        if (value) {
            formDataToSubmit.append(key, value);
        }
    });

    Object.entries(formCustomerData).forEach(([key, value]) => {
        if (value) {
            formDataToSubmit.append(key, value);
        }
    });

    // Append additional data
    formDataToSubmit.append('productList', JSON.stringify(productList));
    formDataToSubmit.append('subtotal', subtotal);
    formDataToSubmit.append('vatIncluded', isVatIncluded);
    formDataToSubmit.append('vatAmount', vatAmount);
    formDataToSubmit.append('grandTotal', grandTotal);
    formDataToSubmit.append('quotationId', quotationId ? quotationId : '');
    formDataToSubmit.append('userEmail', userEmail ? userEmail : '');

    // // Perform the API call
    try {
        const apiUrl = `${omsServiceapiUrl}/quotations/edit`;

        const config = {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
        };

        const response = await axios.post(apiUrl, formDataToSubmit, config);
        const {
            success = false,
            url,
            message,
            quotationId,
          } = response?.data || {};
    
          if (success === true) {
            toast.success(message, {
                style: {
                    border: '1px solid #fff',
                    padding: '20px',
                    color: '#ffffff',
                    background: '#4CAF50',
                  },
                  iconTheme: {
                    primary: '#FFFAEE',
                    secondary: '#4CAF50',
                  },
              });
    
            history.push(`/orders/quotations/create?quotation_id=${quotationId}`);

          } else {
            toast.error(message, {
                style: {
                  border: '1px solid #fff',
                  padding: '20px',
                  color: '#ffffff',
                  background: '#f44336',
                },
                iconTheme: {
                  primary: '#FFFAEE',
                  secondary: '#f44336',
                },
              });
          }

    } catch (error) {
        console.error('Error creating quotation:', error);
    } finally {
        setIsLoading(false);
    }
};

/**
     * fetch data from the api
     */
const fetchDataFromLS = async (sku) => {
    setIsLoading(true);

    if(!sku){
        const message = 'Add SKU to Fetch';
        toast.error(message, {
            style: {
              border: '1px solid #fff',
              padding: '20px',
              color: '#ffffff',
              background: '#f44336',
            },
            iconTheme: {
              primary: '#FFFAEE',
              secondary: '#f44336',
            },
          });
    }else{
  
        const payload = {
            sku: sku,
        };

        try {
            const response = await axios.post(`${omsServiceapiUrl}/quotations/fetch-ls`, payload);
            if (response?.data?.itemNo) {
                             setFormData({
                    sku: sku || '',
                    productName: response?.data?.itemName || '',
                    uom: response?.data?.itemSalesUOM || '',
                    unitPrice: response?.data?.itemNowPrice.replace(/,/g, '') || '',
                });
            } else {
                const message = 'No SKU Found';
                toast.error(message, {
                    style: {
                      border: '1px solid #fff',
                      padding: '20px',
                      color: '#ffffff',
                      background: '#f44336',
                    },
                    iconTheme: {
                      primary: '#FFFAEE',
                      secondary: '#f44336',
                    },
                  });
            }
        } catch (error) {
            console.error("Error exporting report:", error);
            alert("An error occurred while exporting.");
        } finally {
            setIsLoading(false);
        }

    }
  
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  };

  const handlePrint = () => {
    if (printFile) {
        const printWindow = window.open(printFile, '_blank'); // Open the URL in a new tab
        printWindow.onload = () => {
            printWindow.print(); // Trigger the print dialog once the content has loaded
        };
    } else {
        console.log("No file URL to print");
    }
};

const handleSendQuotation = async (method) => {

    setIsLoading(true);

    const errors = validateForm();

     // Create a FormData object
     const formDataToSubmit = new FormData();
     formDataToSubmit.append('method', method);
     formDataToSubmit.append('quotationId', quotationId ? quotationId : '');

    try {

        const apiUrl = `${omsServiceapiUrl}/quotations/send-quotation`;

        const config = {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
        };

        const response = await axios.post(apiUrl, formDataToSubmit, config);
        const { success } = response?.data || {};
    
          if (success === true) {
            const message = 'Quotation send successfully';
            toast.success(message, {
                style: {
                    border: '1px solid #fff',
                    padding: '20px',
                    color: '#ffffff',
                    background: '#4CAF50',
                  },
                  iconTheme: {
                    primary: '#FFFAEE',
                    secondary: '#4CAF50',
                  },
              });
          } else {
            const message = 'Quotation send failed';
            toast.error(message, {
                style: {
                  border: '1px solid #fff',
                  padding: '20px',
                  color: '#ffffff',
                  background: '#f44336',
                },
                iconTheme: {
                  primary: '#FFFAEE',
                  secondary: '#f44336',
                },
              });
          }

        // Handle success (e.g., show success message, redirect, etc.)
    } catch (error) {
        console.error('Error creating quotation:', error);
        // Handle error (e.g., show error message)
    } finally {
        setIsLoading(false); // Reset loading state regardless of success or failure
    }
  };

  const handleCreateOrder = () => {

    history.push(`/orders/create?quotation_id=${quotationId}`);

  }

  return (
      <Container fluid>         
        <Card>
          {/* Table Listing */}
          <CardBody>
                {status === '0' ? (
                        <div id="default-accordion-example" flush style={{ borderBottom: '1px solid lightgrey' }}>
                            <div>
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                    className={`${classnames('accordion-button', {
                                        collapsed: !general,
                                    })} `}
                                    type="button"
                                    style={{ cursor: 'pointer' }}
                                    >
                                    Add a Product
                                    </button>
                                </h2>
                                <Collapse
                                    isOpen={general}
                                    className="accordion-collapse"
                                    id="collapseOne"
                                >
                                    <div className="accordion-body" style={{ color: 'black' }}>
                                        <Row>
                                            <Col md={2} className="mb-2">
                                                <Label htmlFor="sku" className="form-label">
                                                SKU <span style={{ color: 'red' }}>*</span>
                                                </Label>
                                                <Input type="text" className={`form-control ${touched.sku && !formData.sku ? 'is-invalid' : ''}`} id="sku" name="sku" placeholder="Eg: 810401102322" value={formData.sku} onChange={handleInputChange}  onBlur={() => handleBlur('sku')} required/>
                                            </Col>
                                            <Col md={2} className="mb-2">
                                            <Label htmlFor="unitPrice" className="form-label text-white">
                                                {'Fetch From LS'}
                                            </Label>
                                            <Button color="secondary" disabled={isLoading}  onClick={() => fetchDataFromLS(formData.sku)} className='form-control'>
                                            {isLoading ? "Processing..." : "Fetch From LS"}
                                            </Button>
                                            </Col>
                                            </Row>
                                            <Row>
                                            <Col md={4} className="mb-2">
                                                <Label htmlFor="productName" className="form-label">
                                                Product Name <span style={{ color: 'red' }}>*</span>
                                                </Label>
                                                <Input type="text" className={`form-control ${touched.productName && !formData.productName ? 'is-invalid' : ''}`} id="productName" name="productName" placeholder="Eg: Flora" value={formData.productName}  onChange={handleInputChange} onBlur={() => handleBlur('productName')} required/>
                                            </Col>
                                            {/*  */}
                                            <Col md={2} className="mb-2">
                                                <Label htmlFor="uom" className="form-label">
                                                UOM <span style={{ color: 'red' }}>*</span>
                                                </Label>
                                                <Input type="text" className={`form-control ${touched.uom && !formData.uom ? 'is-invalid' : ''}`} id="uom" name="uom" placeholder="Eg: 10" value={formData.uom}  onChange={handleInputChange} onBlur={() => handleBlur('uom')} required/>
                                            </Col>
                                            <Col md={1} className="mb-2">
                                                <Label htmlFor="qty" className="form-label">
                                                Qty <span style={{ color: 'red' }}>*</span>
                                                </Label>
                                                <Input type="text" className={`form-control ${touched.qty && !formData.qty ? 'is-invalid' : ''}`} id="qty" name="qty" placeholder="Eg: 10" value={formData.qty}  onChange={handleInputChange} onBlur={() => handleBlur('qty')} required/>
                                            </Col>
                                            {/*  */}
                                            <Col md={2} className="mb-2">
                                                <Label htmlFor="unitPrice" className="form-label">
                                                Unit Price <span style={{ color: 'red' }}>*</span>
                                                </Label>
                                                <Input type="text" className={`form-control ${touched.unitPrice && !formData.unitPrice ? 'is-invalid' : ''}`} id="unitPrice" name="unitPrice" placeholder="Eg: 99.99" value={formData.unitPrice}  onChange={handleInputChange} onBlur={() => handleBlur('unitPrice')} required/>
                                            </Col>
                                            {/*  */}
                                            <Col md={2} className="mb-2">
                                                <Label htmlFor="totalValue" className="form-label">
                                                Total
                                                </Label>
                                                <Input type="text" className={`form-control`} id="unitPrice" name="totalValue" placeholder="Eg: 100"  value={formData.totalValue || ''}  disabled/>
                                            </Col>
                                            <Col md={1} className="mb-2">
                                            <Label htmlFor="unitPrice" className="form-label text-white">
                                                {isEditing ? 'Update' : 'Add'}
                                            </Label>
                                            <Button color="secondary" onClick={isEditing ? handleUpdateProduct : handleAddProduct} className='form-control'>
                                                {isEditing ? 'Update' : 'Add'}
                                            </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                ) : (
                    <>
                    {edit_quotation_id && (
                    <div id="default-accordion-example" flush style={{ borderBottom: '1px solid lightgrey' }}>
                            <div>
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                    className={`${classnames('accordion-button', {
                                        collapsed: !general,
                                    })} `}
                                    type="button"
                                    style={{ cursor: 'pointer' }}
                                    >
                                    Add a Product
                                    </button>
                                </h2>
                                <Collapse
                                    isOpen={general}
                                    className="accordion-collapse"
                                    id="collapseOne"
                                >
                                    <div className="accordion-body" style={{ color: 'black' }}>
                                            <Row>
                                            <Col md={2} className="mb-2">
                                                <Label htmlFor="sku" className="form-label">
                                                SKU <span style={{ color: 'red' }}>*</span>
                                                </Label>
                                                <Input type="text" className={`form-control ${touched.sku && !formData.sku ? 'is-invalid' : ''}`}id="sku" name="sku" placeholder="Eg: 810401102322" value={formData.sku} onChange={handleInputChange}  onBlur={() => handleBlur('sku')} required/>
                                            </Col>
                                            <Col md={2} className="mb-2">
                                            <Label htmlFor="unitPrice" className="form-label text-white">
                                                {'Fetch From LS'}
                                            </Label>
                                            <Button color="secondary" disabled={isLoading}  onClick={() => fetchDataFromLS(formData.sku)} className='form-control'>
                                            {isLoading ? "Processing..." : "Fetch From LS"}
                                            </Button>
                                            </Col>
                                            </Row>
                                            <Row>
                                            <Col md={4} className="mb-2">
                                                <Label htmlFor="productName" className="form-label">
                                                Product Name <span style={{ color: 'red' }}>*</span>
                                                </Label>
                                                <Input type="text" className={`form-control ${touched.productName && !formData.productName ? 'is-invalid' : ''}`} id="productName" name="productName" placeholder="Eg: Flora" value={formData.productName}  onChange={handleInputChange} onBlur={() => handleBlur('productName')} required/>
                                            </Col>
                                            <Col md={2} className="mb-2">
                                                <Label htmlFor="uom" className="form-label">
                                                UOM <span style={{ color: 'red' }}>*</span>
                                                </Label>
                                                <Input type="text" className={`form-control ${touched.uom && !formData.uom ? 'is-invalid' : ''}`} id="uom" name="uom" placeholder="Eg: 10" value={formData.uom}  onChange={handleInputChange} onBlur={() => handleBlur('uom')} required/>
                                            </Col>
                                            {/*  */}
                                            <Col md={1} className="mb-2">
                                                <Label htmlFor="qty" className="form-label">
                                                Qty <span style={{ color: 'red' }}>*</span>
                                                </Label>
                                                <Input type="text" className={`form-control ${touched.qty && !formData.qty ? 'is-invalid' : ''}`} id="qty" name="qty" placeholder="Eg: 10" value={formData.qty}  onChange={handleInputChange} onBlur={() => handleBlur('qty')} required/>
                                            </Col>
                                            {/*  */}
                                            <Col md={2} className="mb-2">
                                                <Label htmlFor="unitPrice" className="form-label">
                                                Unit Price <span style={{ color: 'red' }}>*</span>
                                                </Label>
                                                <Input type="text" className={`form-control ${touched.unitPrice && !formData.unitPrice ? 'is-invalid' : ''}`} id="unitPrice" name="unitPrice" placeholder="Eg: 99.99" value={formData.unitPrice}  onChange={handleInputChange} onBlur={() => handleBlur('unitPrice')} required/>
                                            </Col>
                                            {/*  */}
                                            <Col md={2} className="mb-2">
                                                <Label htmlFor="totalValue" className="form-label">
                                                Total
                                                </Label>
                                                <Input type="text" className={`form-control`} id="unitPrice" name="totalValue" placeholder="Eg: 100"  value={formData.totalValue || ''}  disabled/>
                                            </Col>
                                            <Col md={1} className="mb-2">
                                            <Label htmlFor="unitPrice" className="form-label text-white">
                                                {isEditing ? 'Update' : 'Add'}
                                            </Label>
                                            <Button color="secondary" onClick={isEditing ? handleUpdateProduct : handleAddProduct} className='form-control'>
                                                {isEditing ? 'Update' : 'Add'}
                                            </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    )}
                        </>
                )}
                    {/* Displaying Added Products in Table */}
                    {productList.length > 0 && (
                        <Table striped bordered className="mt-3">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Product Name</th>
                                    <th>SKU</th>
                                    <th>UOM</th>
                                    <th>Qty</th>
                                    <th>Unit Price</th>
                                    <th>Total Value</th>
                                    {status === '0' ? (
                                    <th>Action</th>
                                    ) : (
                                        <>
                                        {edit_quotation_id && (
                                        <th>Action</th>
                                        )}
                                        </>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {productList.map((product, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td width={600}>{product.productName}</td>
                                        <td>{product.sku}</td>
                                        <td>{product.uom}</td>
                                        <td>{product.qty}</td>
                                        <td>{product.unitPrice}</td>
                                        <td>{product.totalValue}</td>
                                        {status === '0' ? (
                                        <td>
                                        <Button color="success" size="sm" onClick={() => handleEditProduct(index)}>
                                            Update
                                        </Button>{' '}
                                            <Button color="danger" size="sm" onClick={() => handleRemoveProduct(index)}>
                                                Remove
                                            </Button>
                                        </td>
                                        ) : (
                                            <>
                                            {edit_quotation_id && (
                                                <td>
                                                <Button color="success" size="sm" onClick={() => handleEditProduct(index)}>
                                                    Update
                                                </Button>{' '}
                                                    <Button color="danger" size="sm" onClick={() => handleRemoveProduct(index)}>
                                                        Remove
                                                    </Button>
                                                </td>
                                            )}
                                            </>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                    <hr/>
                    {/* Customer Information and Financial Information */}
                    <div className="row mt-4">
                        <div className="col-5 col-lg-4">
                            <div className="mb-3">
                                <label htmlFor="projectId">Project / Lead ID</label>
                                <input type="text" id="projectId" name="projectId" className={`form-control`} value={formCustomerData.projectId} disabled={status === '1' && !edit_quotation_id} onChange={handleInputCustomerChange}  onBlur={() => handleBlur('projectId')} placeholder='Project / Lead ID' />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customerName">Customer Name / Company Name</label>
                                <input type="text" id="customerName" name="customerName" className={`form-control ${touched.customerName && !formCustomerData.customerName ? 'is-invalid' : ''}`} value={formCustomerData.customerName} disabled={status === '1' && !edit_quotation_id} onChange={handleInputCustomerChange}  onBlur={() => handleBlur('customerName')} required placeholder='Customer name or company name' />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phoneNo">Phone No</label>
                                <input type="text" id="phoneNo" name="phoneNo" className={`form-control ${touched.phoneNo && !formCustomerData.phoneNo ? 'is-invalid' : ''}`} value={formCustomerData.phoneNo} onChange={handleInputCustomerChange}  onBlur={() => handleBlur('phoneNo')} required disabled={status === '1' && !edit_quotation_id} placeholder='Phone Number' />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phoneNo">Email</label>
                                <input type="email" id="emailId" name="emailId" className={`form-control`} value={formCustomerData.emailId} onChange={handleInputCustomerChange}  onBlur={() => handleBlur('emailId')} disabled={status === '1' && !edit_quotation_id} placeholder='Email' />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="address">Address</label>
                                <textarea id="address" name="address" className={`form-control ${touched.address && !formCustomerData.address ? 'is-invalid' : ''}`} value={formCustomerData.address} onChange={handleInputCustomerChange}  onBlur={() => handleBlur('address')} required rows="4" disabled={status === '1' && !edit_quotation_id} placeholder="Enter address information here..." />
                            </div>                            
                            <div className="mb-3">
                                <label htmlFor="trnNo">TRN No.</label>
                                <input type="text" id="trnNo" name="trnNo" className={`form-control ${touched.trnNo && !formCustomerData.trnNo ? 'is-invalid' : ''}`} disabled={status === '1' && !edit_quotation_id} value={formCustomerData.trnNo} onChange={handleInputCustomerChange}  onBlur={() => handleBlur('trnNo')} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="paymentType">Payment Type</label>
                                <select
                                    id="paymentType"
                                    name="paymentType"
                                    className={`form-control ${touched.paymentType && !formCustomerData.paymentType ? 'is-invalid' : ''}`}
                                    value={formCustomerData.paymentType}
                                    onChange={handleInputCustomerChange}
                                    onBlur={() => handleBlur('paymentType')}
                                    required
                                    disabled={status === '1' && !edit_quotation_id}
                                >
                                    <option value="">Select Payment Type</option>
                                    <option value="credit">Credit</option>
                                    <option value="debit">Debit</option>
                                    <option value="cash">Cash</option>
                                    <option value="online">Online</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-1 col-lg-5">
                        </div>
                        <div className="col-6 col-lg-3">
                            <div style={{ backgroundColor: 'lightgray', padding: 20, borderRadius: 20 }}>
                                <div className="mb-3 d-none">
                                    <input 
                                        type="checkbox" 
                                        id="excludeVat" 
                                        checked={isVatIncluded} 
                                        onChange={handleCheckboxChange}
                                        disabled={status === '1' || isVatIncluded === true}
                                    />
                                    <label htmlFor="excludeVat" style={{ marginLeft: '10px' }}>VAT Included</label>
                                </div>

                                <div className="mb-3 d-flex justify-content-between">
                                    <label htmlFor="subtotal" style={{width: '50%' }}><b>Subtotal :</b></label>
                                    <div id="subtotal" style={{width: '50%', textAlign: 'right'}}>
                                        {/* Replace with dynamic value */}
                                        {subtotal.toFixed(2)}
                                    </div>
                                </div>

                                {/* {!isVatIncluded && ( */}
                                    <div className="mb-3 d-flex justify-content-between">
                                        <label htmlFor="vat" style={{ width: '50%' }}>VAT Amount (5%) :</label>
                                        <div id="vat" style={{ width: '50%', textAlign: 'right' }}>
                                            {vatAmount.toFixed(2)}
                                        </div>
                                    </div>
                                {/* )} */}
                                <hr/>

                                <div className="mb-3 d-flex justify-content-between">
                                    <label htmlFor="netTotal" style={{width: '50%'}}><b>Grand Total :</b></label>
                                    <div id="netTotal" style={{width: '50%', textAlign: 'right'}}>
                                        {/* Replace with dynamic value */}
                                        {grandTotal.toFixed(2)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <div className="d-flex justify-content-end mt-3">
                        {status === '0' && (
                            <>
                                <Button 
                                color="success" 
                                className="me-2"
                                onClick={() => {
                                    if (quotationId) {
                                        handleUpdate(quotationId); // Call handleUpdate if projectId exists
                                    } else {
                                        handleSubmit(); // Call handleSubmit if projectId is not present
                                    }
                                }}
                                disabled={isLoading}>
                                {isLoading ? "Processing..." : quotationId ? "Update" : "Save"} {/* Dynamic button label */}
                               
                                </Button>
                            </>
                        )}
                        {edit_quotation_id && (
                            <Button 
                                color="success" 
                                className="me-2"
                                onClick={() => {
                                        handleSubmit(); // Call handleSubmit if projectId is not present
                                }}
                                disabled={isLoading}>
                                {isLoading ? "Processing..." : "Save"} {/* Dynamic button label */}
                               
                            </Button>
                        )}
                        {status === '0' ? (
                            <Button 
                            color="danger" 
                            onClick={() => {
                                // Your create logic here
                                handleCreate();
                            }}
                            disabled={isLoading}>
                                {isLoading ? "Processing..." : "Create Quotation"}
                        </Button>
                        ) : (
                            <>
                            {!edit_quotation_id && status !== '2' && (
                                 <>
                                 <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="up">
                                    <DropdownToggle caret color="success" className="ms-2">
                                        Send Quotations
                                    </DropdownToggle>
                                    <DropdownMenu className='dropdown-new-menu'>
                                        <DropdownItem onClick={() => handleSendQuotation('whatsApp')}>
                                        <i className="mdi mdi-whatsapp fs-18 me-2"></i> Send WhatsApp
                                        </DropdownItem>
                                        <DropdownItem onClick={() => handleSendQuotation('email')}>
                                        <i className="mdi mdi-email fs-18 me-2"></i> Send Email
                                        </DropdownItem>
                                        <DropdownItem onClick={() => handleSendQuotation('both')}>
                                        <i className="mdi mdi-share fs-18 me-2"></i> Send Both
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            <Button 
                            className="ms-2"
                                color="primary" 
                                onClick={handlePrint} >
                                Print Quotation
                            </Button>
                            <Button 
                            className="ms-2"
                                color="secondary" 
                                onClick={handleCreateOrder} >
                                Create Order
                            </Button>
                            </>
                            )}
                            </>
                            
                        )}
                        {edit_quotation_id && (
                             <Button 
                             color="danger" 
                             className="ms-2"
                             onClick={() => handleEdit()}
                             disabled={isLoading}>
                                 {isLoading ? "Processing..." : "Create New Quotation"}
                         </Button>

                        )}
                        
                    </div>
                
          </CardBody>
        </Card>
        <Toaster position="top-right" reverseOrder={false} />
      </Container>
    
  );
};

CreateQuote.propTypes = {
    direction: PropTypes.string,
  };

export default CreateQuote;
