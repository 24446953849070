import ApolloClientWagonService from '../../../../ApolloClientWagonService';
import ApolloClientSmileService from '../../../../ApolloClientSmileService';
import {
  VIEW_CART,
  ADD_OR_UPDATE_ITEM_TO_CART,
  REMOVE_ITEM_FROM_CART,
  APPLY_COUPON,
  REMOVE_APPLIED_COUPON,
  SET_PAYMENT_METHOD_TO_CART,
  SET_SHIPPING_ADDRESS_TO_CART,
  SET_EMAIL_TO_GUEST_CART,
  SET_SUPPLIER_ID_FOR_ASSEMBLY_CHARGES,
  VALIDATE_GIFT_CARD,
  VALIDATE_GIFT_CARD_PIN,
  SET_GIFT_CARD_INFORMATION,
  SET_CREDIT_NOTE_INFORMATION,
  SET_SHIPMENT_TIME_SLOT,
  CALCULATE_SHIPMENTS,
  SET_WALLET_INFORMATION,
  ADD_OR_UPDATE_BULK_ITEM_TO_CART,
  SET_SALESMAN_CODE,
} from '../gql/cart';
import axios from 'axios';
import guestUserManagement from '../../../../utils/Auth/guestUserManagement';
import { setCartDetails } from '../../../../Redux/order/createOrderSlice';
import { HOME_DELIVERY_METHOD } from '../../../../constants/index';
const walletApiUrl = process.env.REACT_APP_WALLET_API;
export const viewCart = async ({ }, dispatch) => {
  const guestCartToken = guestUserManagement.getGuestCartToken();
  try {
    const { data = {} } = await ApolloClientWagonService.query({
      query: VIEW_CART,
      variables: { cartToken: guestCartToken },
      fetchPolicy: 'no-cache',
    });

    //if it success set data to state
    const { viewCart = {} } = data || {};
    if (viewCart) {
      dispatch(setCartDetails(viewCart || {}));
    }
    return viewCart;
  } catch (error) {
    // GraphQlErrorToast(error);
  }
};

export const addOrUpdateCart = async (input, dispatch) => {
  const guestCartToken = guestUserManagement.getGuestCartToken();
  try {
    const { data } = await ApolloClientWagonService.mutate({
      mutation: ADD_OR_UPDATE_ITEM_TO_CART,
      variables: {
        input: {
          cartToken: guestCartToken,
          items: [{ ...input }],
        },
      },
      fetchPolicy: 'network-only',
    });
    const {
      cart = {},
      success = false,
      message = 'Successfully updated...',
    } = data?.addToCart || {};
    if (success) {
      if (!guestCartToken && cart?._id && cart?.isGuest) {
        guestUserManagement.setGuestCartToken(cart._id);
      }
      // SuccessToast({ message });
      dispatch(setCartDetails(cart));
      return cart;
    } else {
      //GraphQlErrorToast({ message: 'Something went wrong, Please try again...' });
    }
  } catch (error) {
    console.log(error);
    if (error?.message === `We couldn't find any active cart..!`) {
      guestUserManagement.deleteGuestCartToken();
    }
    //GraphQlErrorToast(error);
  }
};

export const addOrUpdateBulkCart = async (input, dispatch) => {
  const guestCartToken = guestUserManagement.getGuestCartToken();
  try {
    const { data } = await ApolloClientWagonService.mutate({
      mutation: ADD_OR_UPDATE_BULK_ITEM_TO_CART,
      variables: {
        input: {
          cartToken: guestCartToken,
          items: [{ ...input }],
        },
      },
      fetchPolicy: 'network-only',
    });
    const {
      cart = {},
      success = false,
      message = 'Successfully updated...',
    } = data?.bulkAddToCartFormOms || {};
    if (success) {
      if (!guestCartToken && cart?._id && cart?.isGuest) {
        guestUserManagement.setGuestCartToken(cart._id);
      }
      // SuccessToast({ message });
      dispatch(setCartDetails(cart));
      return cart;
    } else {
      //GraphQlErrorToast({ message: 'Something went wrong, Please try again...' });
    }
  } catch (error) {
    console.log(error);
    if (error?.message === `We couldn't find any active cart..!`) {
      guestUserManagement.deleteGuestCartToken();
    }
    //GraphQlErrorToast(error);
  }
};

export const removeItemFromCart = async ({ itemId }, dispatch) => {
  const guestCartToken = guestUserManagement.getGuestCartToken();
  try {
    const { data } = await ApolloClientWagonService.mutate({
      mutation: REMOVE_ITEM_FROM_CART,
      variables: { cartToken: guestCartToken, itemId },
      fetchPolicy: 'network-only',
    });

    //Show Toast
    //SuccessToast({ message: 'Item removed from your cart' });
    const { removeFromCart = {} } = data || {};
    if (removeFromCart) {
      dispatch(setCartDetails(removeFromCart || {}));
    }
    return removeFromCart;
  } catch (error) {
    //addWebLog(error);
    // GraphQlErrorToast(error);
  }
};

export const applyCoupon = async ({ couponCode, cartMeta }, dispatch) => {
  // get cart token from cookies
  const guestCartToken = guestUserManagement.getGuestCartToken();

  // apply coupon
  try {
    const { data } = await ApolloClientWagonService.mutate({
      mutation: APPLY_COUPON,
      variables: { input: { cartToken: guestCartToken, couponCode, cartMeta } },
      fetchPolicy: 'network-only',
    });

    //if it success set data to state
    const { applyCoupon = {} } = data || {};
    const { cart } = applyCoupon || {};
    if (cart) {
      dispatch(setCartDetails(cart || {}));
    }
    return cart || {};
  } catch (error) {
    //addWebLog(error);
    //GraphQlErrorToast(error);
  }
};

export const removeCoupon = async ({ }, dispatch) => {
  // get cart token from cookies
  const guestCartToken = guestUserManagement.getGuestCartToken();

  // remove coupon
  try {
    const { data } = await ApolloClientWagonService.query({
      query: REMOVE_APPLIED_COUPON,
      variables: { cartToken: guestCartToken },
      fetchPolicy: 'network-only',
    });

    //if it success set data to state
    const { removeCoupon = {} } = data || {};
    const { cart } = removeCoupon || {};
    if (cart) {
      dispatch(setCartDetails(cart || {}));
    }
    return cart || {};
  } catch (error) {
    //addWebLog(error);
    //GraphQlErrorToast(error);
  }
};

/**
 * Set payment method to cart
 *
 * @param {*} param0
 * @param {*} dispatch
 */
export const setPaymentMethodCart = async ({ paymentMethodCode }, dispatch) => {
  // get cart token from cookies
  const guestCartToken = guestUserManagement.getGuestCartToken();
  //
  try {
    const { data } = await ApolloClientWagonService.mutate({
      mutation: SET_PAYMENT_METHOD_TO_CART,
      variables: { cartToken: guestCartToken, code: paymentMethodCode },

      fetchPolicy: 'network-only',
    });

    //if it success set data to state
    const { setPaymentMethodToCart = {} } = data || {};
    if (setPaymentMethodToCart) {
      dispatch(setCartDetails(setPaymentMethodToCart || {}));
    }
    return setPaymentMethodToCart;
  } catch (error) {
    //addWebLog(error);
    //GraphQlErrorToast(error);
  }
};

export const setShippingAddress = async (
  { address, deliveryMethodCode = 'bestway', clickAndCollectStoreCode = null },
  dispatch
) => {
  // get cart token from cookies
  const guestCartToken = guestUserManagement.getGuestCartToken();
  try {
    if (address) {
      const { data } = await ApolloClientWagonService.mutate({
        mutation: SET_SHIPPING_ADDRESS_TO_CART,
        variables: {
          input: {
            cartToken: guestCartToken,
            shippingAddress: address,
            deliveryMethodCode: deliveryMethodCode,
            clickAndCollectStoreCode: clickAndCollectStoreCode,
          },
        },
        fetchPolicy: 'network-only',
      });
      //if it success set data to state
      const { setShippingAddress = {} } = data || {};
      if (setShippingAddress) {
        dispatch(setCartDetails(setShippingAddress || {}));
      }
      return setShippingAddress;
    }
  } catch (error) {
    console.log('error==>', error);
    //addWebLog(error);
    // GraphQlErrorToast(error);
  }
};

export const removeCartToken = async ({ }, dispatch) => {
  // get cart token from cookies
  guestUserManagement.deleteGuestCartToken(
    guestUserManagement.getGuestCartToken()
  );
  dispatch(setCartDetails({}));
};

/**
 * Set email to guest cart
 *
 * @param {*} param0
 * @param {*} dispatch
 * @returns
 */
export const setEmailToGuestCart = async (email, dispatch) => {
  // get cart token from cookies
  const guestCartToken = guestUserManagement.getGuestCartToken();
  //
  try {
    const { data } = await ApolloClientWagonService.query({
      query: SET_EMAIL_TO_GUEST_CART,
      variables: { cartToken: guestCartToken, email: email },
      fetchPolicy: 'network-only',
    });

    //if it success set data to state
    const { setEmailToGuestCart = {} } = data || {};

    if (setEmailToGuestCart) {
      dispatch(setCartDetails(setEmailToGuestCart || {}));
    }
    return setEmailToGuestCart;
  } catch (error) {
    //addWebLog(error);
  }
};

/**
 * set and unset supplier for assembly charges
 *
 * @param {*} param0
 * @param {*} dispatch
 * @returns
 */
export const setSupplierIdsForAssemblyCharges = async ({ ids }, dispatch) => {
  // get cart token from cookies
  const guestCartToken = guestUserManagement.getGuestCartToken();

  // apply  assembly charges
  try {
    const { data } = await ApolloClientWagonService.mutate({
      mutation: SET_SUPPLIER_ID_FOR_ASSEMBLY_CHARGES,
      variables: { input: { cartToken: guestCartToken, ids, ids } },
      fetchPolicy: 'no-cache',
    });

    //if it success set data to state
    const { setAssemblySupplierId = {} } = data || {};
    if (setAssemblySupplierId) {
      dispatch(setCartDetails(setAssemblySupplierId || {}));
    }
    return setAssemblySupplierId || {};
  } catch (error) {
    //addWebLog(error);
    //GraphQlErrorToast(error);
  }
};

/**
 * Validate Gift Card
 *
 * @param {*} param0
 * @param {*} dispatch
 * @returns
 */
export const validateGiftCard = async ({ giftCardNumber }) => {
  // validate gift card
  try {
    const { data } = await ApolloClientSmileService.query({
      query: VALIDATE_GIFT_CARD,
      variables: { input: { giftVoucherNumber: giftCardNumber } },
      fetchPolicy: 'no-cache',
    });
    console.log(data, 'data');
    // //if it success set data to state
    const { validateGiftvoucher = {} } = data || {};

    return validateGiftvoucher || {};
  } catch (error) {
    //addWebLog(error);
    //GraphQlErrorToast(error);
  }
};

/**
 * Validate Gift Card PIN
 *
 * @param {*} param0
 * @param {*} dispatch
 * @returns
 */
export const validateGiftCardPin = async ({ giftCardNumber, pin }) => {
  // validate gift card
  try {
    const { data } = await ApolloClientSmileService.query({
      query: VALIDATE_GIFT_CARD_PIN,
      variables: { input: { giftVoucherNumber: giftCardNumber, pin: pin } },
      fetchPolicy: 'no-cache',
    });
    // //if it success set data to state
    const { validateGiftvoucherPin = {} } = data || {};

    return validateGiftvoucherPin || {};
  } catch (error) {
    //addWebLog(error);
    //GraphQlErrorToast(error);
  }
};

/**
 * Update Gift Card
 *
 * @param {*} param0
 * @param {*} dispatch
 * @returns
 */
export const updateGiftCardInformation = async (cardInfo, dispatch) => {
  // get cart token from cookies
  const guestCartToken = guestUserManagement.getGuestCartToken();
  try {
    const { data } = await ApolloClientWagonService.mutate({
      mutation: SET_GIFT_CARD_INFORMATION,
      variables: { input: { cartToken: guestCartToken, ...cardInfo } },
      fetchPolicy: 'no-cache',
    });

    //if it success set data to state
    const { setGiftCardInformation = {} } = data || {};
    if (setGiftCardInformation) {
      dispatch(setCartDetails(setGiftCardInformation || {}));
    }
    return setGiftCardInformation || {};
  } catch (error) {
    //addWebLog(error);
    //GraphQlErrorToast(error);
  }
};

/**
 * Validate Credit note
 *
 * @param {*} param0
 * @param {*} dispatch
 * @returns
 */
export const applyCreditNote = async (creditNoteNumber, dispatch) => {
  try {
    // get cart token from cookies
    const guestCartToken = guestUserManagement.getGuestCartToken();
    const { data } = await ApolloClientWagonService.mutate({
      mutation: SET_CREDIT_NOTE_INFORMATION,
      variables: { input: { ...creditNoteNumber, cartToken: guestCartToken } },
      fetchPolicy: 'no-cache',
    });
    console.log(data, 'data');
    const { setCreditNoteInformation = {} } = data || {};
    if (setCreditNoteInformation) {
      console.log('setCreditNoteInformation', setCreditNoteInformation);
      dispatch(setCartDetails(setCreditNoteInformation || {}));
    }
    return setCreditNoteInformation || {};
  } catch (error) {
    //addWebLog(error);
    //GraphQlErrorToast(error);
  }
};

/**
 * Calculate shipment
 *
 * @param {*} param0
 * @param {*} dispatch
 */
export const setShipmentTimeAction = async (
  { timeId, shipmentId },
  dispatch
) => {
  // get cart token from cookies
  const guestCartToken = guestUserManagement.getGuestCartToken();
  //

  if (!guestCartToken) {
    return;
  }

  try {
    const { data = {} } = await ApolloClientWagonService.query({
      query: SET_SHIPMENT_TIME_SLOT,
      variables: { input: { cartToken: guestCartToken, timeId, shipmentId } },
      fetchPolicy: 'no-cache',
    });

    //if it success set data to state
    const { setShipmentSlot = {} } = data || {};
    if (setShipmentSlot) {
      dispatch(setCartDetails(setShipmentSlot || {}));
    }
    return setShipmentSlot;
  } catch (error) {
    // if cart missing error comes , remove guest cart token
    if (error?.message === `We couldn't find any active cart..!`) {
      guestUserManagement.deleteGuestCartToken();
    }
  }
};

/**
 * Calculate shipment
 *
 * @param {*} param0
 * @param {*} dispatch
 */
export const calculateShipments = async (dispatch) => {
  // get cart token from cookies
  const guestCartToken = guestUserManagement.getGuestCartToken();
  //

  if (!guestCartToken) {
    return;
  }

  try {
    const { data = {} } = await ApolloClientWagonService.query({
      query: CALCULATE_SHIPMENTS,
      variables: { cartToken: guestCartToken },
      fetchPolicy: 'no-cache',
    });

    //if it success set data to state
    const { calculateShipments = {} } = data || {};
    if (calculateShipments) {
      dispatch(setCartDetails(calculateShipments || {}));
    }
    return calculateShipments;
  } catch (error) {
    // if cart missing error comes , remove guest cart token
    if (error?.message === `We couldn't find any active cart..!`) {
      guestUserManagement.deleteGuestCartToken();
    }
  }
};
export const getWalletBalance = async (data, dispatch) => {
  return axios({
    method: 'post',
    url: `${walletApiUrl}/wallet/balance`,
    data: data,
  })
    .then(async (response) => {
      if (response?.status === 200) {
        return response?.data;
      } else {
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
export const generateWalletOTP = async (data, dispatch) => {
  return axios({
    method: 'post',
    url: `${walletApiUrl}/otp/generate`,
    data: data,
  })
    .then(async (response) => {
      if (response?.status === 200) {
        return response?.data;
      } else {
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
export const VerifyWalletOTP = async (data, dispatch) => {
  return axios({
    method: 'post',
    url: `${walletApiUrl}/otp/verify`,
    data: data,
  })
    .then(async (response) => {
      if (response?.status === 200) {
        return response?.data;
      } else {
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
export const setWalletInfo = async (inputData, showError = true) => {
  try {
    const { data } = await ApolloClientWagonService.mutate({
      mutation: SET_WALLET_INFORMATION,
      variables: { input: inputData },
      fetchPolicy: 'network-only',
      headers: {
        requestAppType: 'oms',
      },
    });

    if (data?.setWalletInformation?._id) {
      return data?.setWalletInformation;
    }
  } catch (error) {
    console.log(error);
  }
};

export const setSalesPersonCode = async (omsConfigData, dispatch) => {
  if (typeof dispatch !== 'function') {
    console.error('Dispatch is not a function. Ensure you are passing it correctly.');
    return;
  }

  const guestCartToken = guestUserManagement.getGuestCartToken();

  try {
    const { data } = await ApolloClientWagonService.query({
      query: SET_SALESMAN_CODE,
      variables: {
        data: { salesManCode: omsConfigData.salesmanCode },
        cartToken: guestCartToken
      },
      fetchPolicy: 'network-only',
    });

    if (data?.updateOmsConfig) {
      dispatch(setCartDetails(data.updateOmsConfig || {}));
    }
    return data.updateOmsConfig || {};
  } catch (error) {
    console.error(error, 'GraphQL Query Error');
  }
};


