import React from 'react';
import { MetaTags } from 'react-meta-tags';
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from '../../components/Common/Breadcrumb';
import ReturnOrderList from './components/ReturnOrderList';
import 'react-datepicker/dist/react-datepicker.css';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';

import { Col, Container, Row, FormGroup, InputGroup } from 'reactstrap';
import ReturnExportReport from '../Returns/components/ReturnExportReport';
import { EXPORT_ALLOWED_USER_ROLES } from '../../constants';

const ReturnOrders = () => {
  const title = 'Orders';
  const description = 'Manage Return Orders';
  const users = JSON.parse(localStorage?.getItem('authUser'));
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mange Return Orders</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Orders"
                breadcrumbItem="Manage Return Orders"
              />
            </Col>
            <Col className="textAlignRight ">
              <a href={`/CreateFormReturn`} className="btn btn-dark">
                Create New Returns
              </a>
            </Col>
            <Col>
              {EXPORT_ALLOWED_USER_ROLES.includes(users?.role_id) ? (
                <ReturnExportReport users={users} />
              ) : (
                ''
              )}
            </Col>
          </Row>

          <ReturnOrderList />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReturnOrders;
