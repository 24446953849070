import React, { useEffect, useState, useCallback } from 'react';
import './ListingQuote.scss';
import 'react-drawer/lib/react-drawer.css';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import {
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Spinner,
  Input
} from 'reactstrap';
import axios from 'axios';
import UserAuthKey from "../../../../../components/Common/UserAuthKey";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

const ListingQuotationComponent = () => {
  const history = useHistory();

  const omsServiceapiUrl = process.env.REACT_APP_OMS_NEST_API;
  const bearerToken = UserAuthKey();

  let userEmail= ''; 
  if (localStorage.getItem("authUser")) {
    const userObj = JSON.parse(localStorage.getItem("authUser"));
    if (userObj?.role_id !== undefined) {
      userEmail = userObj?.user_email;
    }
  }

  const [reloadListApi, setReloadListApi] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentItem, setCurrentItem] = useState(0);

  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [listData, setListData] = useState([]);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [quoteId, setQuotationId] = useState(false);

  function editModalModal(quotationId) {
    setEditModal(!editModal);
    setQuotationId(quotationId);
  }

  const isViewData = (quoteId) => {
    history.push(`/orders/quotations/create?quotation_id=${quoteId}`);
  };

  const fetchQuoteList = useCallback(async (data) => {
    setIsLoading(true);

    const { pagination, filter } = data;
    const { page, limit } = pagination;

    try {

      const payload = {
        filter: { ...filter },
        pagination: { page, limit },
    };

      const response = await axios.post(`${omsServiceapiUrl}/quotations/fetch-quotes`, payload);
     
      const { items, pageInfo } = response.data || {};
      const { currentPage, totalPages, currentItem, totalItems } =
        pageInfo || {};

      setListData(items || []);
      setCurrentPage(Math.min(currentPage, totalPages) || 1);
      setTotalPages(totalPages || 1);
      setCurrentItem(currentItem || 0);
      setTotalItems(totalItems || 0);
      
    } catch (error) {
      console.error('Failed to fetch quotes list:', error);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  }, []);

  useEffect(() => {
    const delay = setTimeout(() => {
      fetchQuoteList({
        pagination: { page: currentPage, limit: 10 },
        filter: {
          search: search,
          status: '',
        },
      });
    }, 300); // Debounce time of 300 milliseconds
    setReloadListApi(false);
    return () => clearTimeout(delay);
  }, [
    search, currentPage, reloadListApi, fetchQuoteList,
  ]);
  
  const handleSearchInputChange = ({ target }) => {
    setSearch(target?.value || ""); // Update search term on input change
    setIsSearchEnabled(!!target?.value || ''); // Enable button when there's a search term or lead type selected
  };

  const handleEdit = async (quoteId) => {
    setIsLoading(true);

    // Create a FormData object
    const formDataToSubmit = new FormData();

    formDataToSubmit.append('quotationId', quoteId ? quoteId : '');
    formDataToSubmit.append('userEmail', userEmail ? userEmail : '');


    // // Perform the API call
    try {
      const apiUrl = `${omsServiceapiUrl}/quotations/cancel`;

      const config = {
          headers: {
              Authorization: `Bearer ${bearerToken}`,
              'Content-Type': 'application/json',
            },
      };

        const response = await axios.post(apiUrl, formDataToSubmit, config);
        const {
            success = false,
            url,
            message,
            quotationId,
          } = response?.data || {};
    
          if (success === true) {
            toast.success(message, {
              style: {
                  border: '1px solid #fff',
                  padding: '20px',
                  color: '#ffffff',
                  background: '#4CAF50',
                },
                iconTheme: {
                  primary: '#FFFAEE',
                  secondary: '#4CAF50',
                },
            });

            history.push(`/orders/quotations/create?edit_quotation_id=${quoteId}`);

          } else {
            toast.error(message, {
              style: {
                border: '1px solid #fff',
                padding: '20px',
                color: '#ffffff',
                background: '#f44336',
              },
              iconTheme: {
                primary: '#FFFAEE',
                secondary: '#f44336',
              },
            });
          }

        // Handle success (e.g., show success message, redirect, etc.)
    } catch (error) {
        console.error('Error canceling quotation:', error);
        // Handle error (e.g., show error message)
    } finally {
        setIsLoading(false); // Reset loading state regardless of success or failure
    }
};

  return (
    <div className="table-responsive dt-table">
      {' '}
      <Row className=" dh-TabRow textAlignRight ">
        <Col className="MobileView textAlignRight ">
          <div className="search-box me-2  d-inline-block">
            <div className="position-relative">
              <Form.Control
                type="text"
                placeholder="Search by Quotation ID"
                value={search}
                id="listSearch"
                onChange={handleSearchInputChange}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
        </Col>
      </Row>
      {listData?.length > 0 ? (
        <>
          <Table className="OrderListTable table mb-0">
            <thead>
              <tr>
                <th>SI.No</th>
                <th>Quotation ID</th>
                <th nowrap="nowrap">Customer Name</th>
                <th>Mobile</th>
                <th>Address</th>
                <th>TRN No.</th>
                <th>Total Value</th>
                <th>Status</th>
                <th>Modified At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {(listData || []).map((item, key) => (
                        <tr key={key}>
                          <td className="id">
                            <span className="link-primary fw-medium">
                              {key + 1}
                            </span>
                          </td>
                          <td>{item?.quotation_id}</td>
                          <td>{item?.customer_name}</td>
                          <td>{item?.mobile}</td>
                          <td>{item?.address}</td>
                          <td>{item?.trn_no}</td>
                          <td>AED {item?.grand_total}</td>
                          <td>
                            <span
                                className={`badge text-capitalize ${
                                    item?.status === '0' 
                                      ? 'bg-success'
                                      : item?.status === '1'
                                      ? 'bg-secondary'
                                      : item?.status === '2'
                                      ? 'bg-danger'
                                      : 'bg-warning'
                                  }`}
                            >
                                {item?.status === '0' ? 'Saved' : item?.status === '1' ? 'Created' : item?.status === '2' ? 'Canceled' : item?.status}
                            </span>
                            </td>

                          <td>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                              {item?.created_by}
                                </p>
                                <p className="text-muted mb-0">
                                {moment(item?.created_at).format(
                                    'D MMM, YYYY h:mm A'
                                  )}
                                </p>
                                {item?.updated_at  && (
                                <p className="text-dark mb-0">
                                  {moment(item?.updated_at).format(
                                    'D MMM, YYYY h:mm A'
                                  )}
                                </p>
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            <ul className="list-inline hstack gap-2 mb-0">
                             <li
                                style={{ cursor: 'pointer' }}
                                className="btn btn-sm btn-outline-warning waves-effect waves-light list-inline-item text-primary d-inline-block"
                                data-bs-toggle="tooltip"
                                data-bs-trigger="hover"
                                data-bs-placement="top"
                                title="View"
                                onClick={() => {
                                    isViewData(item.quotation_id);
                                }}
                              >
                                <i className="mdi mdi-eye-outline fs-16"></i>
                              </li>
                              {item?.status === '1' && (
                              <li
                                style={{ cursor: 'pointer' }}
                                className="btn btn-sm btn-outline-warning waves-effect waves-light list-inline-item text-primary d-inline-block"
                                data-bs-toggle="tooltip"
                                data-bs-trigger="hover"
                                data-bs-placement="top"
                                title="Edit"
                                onClick={() => {
                                  editModalModal(item.quotation_id);
                                }}
                              >
                                <i className="mdi mdi-border-color fs-16"></i>
                              </li>
                              )}
                            </ul>
                          </td>
                        </tr>
                      ))}
            </tbody>
          </Table>
          <div className="dataTblFooter">
          <div className="align-items-center mt-xl-3 mt-4 justify-content-between d-flex">
                  <div className="flex-shrink-0">
                    <div className="text-muted">
                      Showing &nbsp;
                      <span className="fw-semibold">{currentItem}</span>
                      &nbsp; of &nbsp;
                      <span className="fw-semibold">{totalItems}</span>
                      &nbsp; Results
                    </div>
                  </div>
                </div>

            <div className="d-flex justify-content-center">
            <Row className="justify-content-md-end justify-content-center align-items-center">
              <Col className="col-md-auto">
                Page{' '}
                <strong>
                  {currentPage}/{totalPages}
                </strong>
              </Col>
              <Col className="col-md-auto">
                <div className="d-flex gap-1">
                  <Button
                    color="primary"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    {'<'}
                  </Button>
                </div>
              </Col>

              <Col className="col-md-auto">
                <Input
                  type="number"
                  min={1}
                  style={{ width: 70 }}
                  max={totalPages}
                  value={currentPage}
                  onChange={(event) => {
                    setCurrentPage(parseInt(event?.target?.value || 0));
                  }}
                />
              </Col>

              <Col className="col-md-auto">
                <div className="d-flex gap-1">
                  <Button
                    color="primary"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    {'>'}
                  </Button>
                </div>
              </Col>
            </Row>

            </div>
          </div>
        </>
      ) : (
        <>
          <Table className="OrderListTable table mb-0">
            <thead>
            <tr>
                <th>SI.No</th>
                <th>Quotation ID</th>
                <th nowrap="nowrap">Customer Name</th>
                <th>Mobile</th>
                <th>Address</th>
                <th>TRN No.</th>
                <th>Total Value</th>
                <th>Status</th>
                <th>Modified At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={12} style={{textAlign: 'center'}}>No Records found</td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
      <Modal
        isOpen={editModal}
        toggle={() => {
            editModalModal();
        }}
        backdrop={'static'}
        id="staticBackdrop"
        centered
        size="md"
      >
        <ModalHeader
          className="modal-title"
          id="rolesAndPermissions"
          toggle={() => {
            editModalModal();
          }}
        >
          Confirm before edit?
        </ModalHeader>
        <ModalBody>
          <div>
            <p className="pb-3 fs-14 text-start m-0 d-flex flex-column">
            Are you sure you want to edit this quotation? This action will create a new quotation, and the old quotation will be canceled. This action cannot be undone.
            </p>
            <div className="d-flex gap-2 justify-content-start align-items-center">
            <Button color="success" size="md" onClick={() => handleEdit(quoteId)} disabled={isLoading}>
              {isLoading ? (
                <Spinner size="sm" />  // This is the loader
              ) : (
                'Yes, Edit'
              )}
            </Button>

              <Button outline color="dark" size="md" onClick={() => editModalModal()}>
                No
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};
export default ListingQuotationComponent;
