import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlSearchParams } from 'use-url-search-params';
import { Form } from 'react-bootstrap';
import { Badge } from 'reactstrap';
import classnames from 'classnames';
import Pagination from '@vlsergey/react-bootstrap-pagination';
import { getAllReturnOrders } from '../actions/orderActions';
import { SORTING_ORDER } from '../../../constants/index';

import moment from 'moment';

import {
  Table,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  NavLink,
  Nav,
  NavItem,
  Button,
} from 'reactstrap';
import FilterSearchQueue from '../../../components/FilterSearch/FilterSearchQueue';
import DHPagination from '../../../components/Pagination';
import axios from 'axios';
import toast from 'react-hot-toast';

const ReturnOrderList = () => {
  const location = useLocation();
  const [btnprimary1, setBtnprimary1] = useState(false);
  const dispatch = useDispatch();
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: '_date',
    order: SORTING_ORDER.ASC,
  });

  let currentCountry = localStorage.getItem('WEBSITE_COUNTRY');

  const splitVal = location?.pathname.split('/');
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('return');
  const [advancedFilter, setAdvancedFilter] = useState({});
  const [searchFilter, setSearchFilter] = useUrlSearchParams({ search: '' });
  const [statusFilter, setStatusFilter] = useUrlSearchParams({ status: 'All' });
  const [returnAWBNo, setReturnAWBNo] = useState(null);

  const apiUrl = process.env.REACT_APP_OMS_NEST_API;
  const generateDHAWB = async (returnNo) => {
    try {
      setReturnAWBNo(returnNo); // Show the loader
      const response = await axios.get(`${apiUrl}/order/retunawb/${returnNo}`, {
        responseType: 'blob', // Binary data
        withCredentials: true, // Match credentials setting in the backend
      });
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(pdfBlob);
      link.download = `returnawb-${returnNo}.pdf`;
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    } finally {
      setReturnAWBNo(null); // Hide the loader
    }
  };

  const fetchReturnData = useCallback(async (data) => {
    try {
      setLoading(true);
      await getAllReturnOrders({
        params: data,
        dispatch,
      });
    } catch (error) {
      setLoading(false);
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const delay = setTimeout(() => {
      fetchReturnData({
        country: currentCountry,
        start: Number(paginationValue.page * limitValue.limit),
        limit: Number(limitValue.limit),
        sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
        search_qry: searchFilter?.search,
        status: statusFilter?.status,
        advancedFilter,
        orderBy: 'id',
      });
    }, 300);
    return () => clearTimeout(delay);
  }, [
    paginationValue.page,
    limitValue,
    advancedFilter,
    searchFilter,
    statusFilter,
  ]);

  const limitByValues = [5, 10, 20, 50];
  const { returnList = [], isLoading } = useSelector((state) => state.order);
  const { returnStatus = [], isreturnStatusLoading } = useSelector(
    (state) => state.order
  );

  const [isDataLoading, setDataLoading] = useState(false);

  const { listOrders } = returnList;
  const orderData = listOrders?.orderList;

  const stsCounts = returnStatus?.data;
  var statusArr = [];
  // const countsData = { ...statusArr };
  let getValue = (statusArr, StsKey) => {
    let returnSts = 0;
    statusArr.forEach((o) => {
      if (o[StsKey] !== undefined) {
        returnSts = o[StsKey];
      }
    });
    return returnSts;
  };

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };

  let isStatusFilter = JSON.parse(localStorage.getItem('returnFilter'));

  useEffect(() => {
    if (returnList?.code === 100) {
      // setApiCall(1);
      //setTimeout(() => setApiCall(0), 5000)
    } else {
    }
  }, [listOrders]);
  let pageIndex = Number(listOrders?.start);

  return (
    <>
      <FilterSearchQueue
        type={type}
        setAdvancedFilter={setAdvancedFilter}
        setSearchFilter={setSearchFilter}
        setStatusFilter={setStatusFilter}
      />

      <div className=" dt-table">
        <div className="table-responsive">
          <Table className="OrderListTable table mb-0">
            <thead>
              <tr>
                <th>SI.No</th>
                <th>Return No</th>
                <th>Country</th>
                <th>Sku</th>
                <th>Order Reference</th>
                <th>Quantity</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            {loading || isDataLoading ? (
              <>
                <Spinner className="ms-2 sploader" color="primary" />
                <tbody>
                  {[...Array(2)].map((_, index) => (
                    <tr>
                      <td>
                        <p className="placeholder-glow">
                          <span className="placeholder col-2"></span>
                        </p>
                      </td>
                      <td>
                        <p className="placeholder-glow">
                          <span className="placeholder col-12"></span>
                          <span className="placeholder col-6"></span>
                          <span
                            className="placeholder"
                            style={{ width: '25%' }}
                          ></span>
                          <span className="placeholder w-75"></span>
                        </p>
                      </td>
                      <td>
                        {' '}
                        <p className="placeholder-glow d-flex flex-column gap-1">
                          <span className="placeholder col-6"></span>
                        </p>
                      </td>
                      <td>
                        {' '}
                        <p className="placeholder-glow">
                          <span className="placeholder col-6"></span>
                        </p>
                      </td>
                      <td>
                        {' '}
                        <p className="placeholder-glow">
                          <span className="placeholder col-6"></span>
                        </p>
                      </td>
                      <td>
                        {' '}
                        <p className="placeholder-glow">
                          <span className="placeholder col-6"></span>
                        </p>
                      </td>
                      <td>
                        {' '}
                        <p className="placeholder-glow">
                          <span className="placeholder col-6"></span>
                          <span
                            className="placeholder"
                            style={{ width: '25%' }}
                          ></span>
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : orderData?.length > 0 ? (
              <>
                <tbody>
                  {(orderData || []).map((order, index) => {
                    pageIndex++;
                    let LsSts = '';
                    const { entity_id: orderid } = order;
                    let order_id = order.entity_id;
                    let split = 'All';
                    //  console.log("order_id----------",order_id);
                    let cdate = order.ReturnDate,
                      orderDt = moment(new Date(cdate)).format('Do MMM, YYYY'),
                      orderTime = moment(new Date(cdate)).format('h:mm:ss a');

                    let stsBtn = 'bg-soft-dark text-dark';
                    if (
                      order.Status.toLowerCase() === 'pending' ||
                      order.Status === 'Cancel Pending'
                    ) {
                      stsBtn = 'bg-soft-warning text-dark';
                      LsSts = 'Pending';
                    } else if (order.Status.toLowerCase() === 'authorized') {
                      stsBtn = 'bg-soft-info text-dark';
                      LsSts = 'Authorized';
                    } else if (
                      order.Status.toLowerCase() === 'received' ||
                      order.Status.toLowerCase() === 'return_drop_success'
                    ) {
                      stsBtn = 'bg-soft-success text-dark';
                      LsSts = 'Received';
                    } else if (order.Status === 'return_scheduled') {
                      stsBtn = 'bg-soft-info text-dark';
                      LsSts = 'Return scheduled';
                    } else if (order.Status === 'return_initated') {
                      stsBtn = 'bg-soft-info text-dark';
                      LsSts = 'Return initiated';
                    } else if (order.Status === 'return_pickup_fail') {
                      stsBtn = 'bg-soft-danger text-dark';
                      LsSts = 'Return Pickup Failed';
                    } else if (order.Status === 'out_for_return_pickup') {
                      stsBtn = 'bg-soft-info text-dark';
                      LsSts = 'Return out for pickup';
                    } else if (order.Status.toLowerCase() === 'approved') {
                      LsSts = 'Approved';
                      stsBtn = 'bg-soft-success text-dark';
                    } else if (
                      order.Status.toLowerCase() === 'rejected' ||
                      order.Status.toLowerCase() === 'canceled' ||
                      order.Status === 'return_canceled' ||
                      order.Status === 'pending_payment' ||
                      order.Status === 'cancel'
                    ) {
                      if (order.Status.toLowerCase() === 'rejected') {
                        LsSts = 'Rejected';
                      } else {
                        LsSts = 'Canceled';
                      }
                      stsBtn = 'bg-soft-danger text-dark';
                    } else {
                      LsSts = `${order.Status}`;
                    }
                    return (
                      <tr key={order?.ReturnNo}>
                        <td scope="row">{pageIndex}</td>
                        <td>
                          <p className="mb-0">
                            <b>{order?.ReturnNo}</b>
                          </p>
                          {order?.cst_no ? (
                            <p className="m-0 text-info">
                              <b>{order?.cst_no}</b>
                            </p>
                          ) : null}

                          <span className="highlightInfo2 ">
                            {orderDt} {orderTime}
                          </span>
                        </td>
                        <td>{order?.country.toUpperCase()}</td>
                        <td>{order?.ItemSku}</td>
                        <td>
                          <p className="mb-0">
                            <a
                              className="text-info"
                              href={`returnorders/${order?.ReturnId}/${order?.OrderId}`}
                              target="_blank"
                            >
                              {order?.OrderId}
                            </a>
                          </p>
                          <p className="mb-0 mt-0">
                            LS Ref:{' '}
                            <b className="fs-11">{order?.ls_order_ref}</b>
                          </p>
                        </td>
                        <td>{order?.OrderQty}</td>
                        <td>
                          <Badge className={` ${stsBtn}`}>{LsSts}</Badge>
                        </td>

                        <td>
                          <div className="d-flex gap-2 justify-content-start align-items-center">
                            <a
                              href={`returnorders/${order?.ReturnId}/${order?.OrderId}`}
                              type="button"
                              className="btn btn-sm btn-outline-warning waves-effect waves-light"
                            >
                              <i className="mdi mdi-eye-outline"></i>{' '}
                            </a>
                            {order?.cst_no ? (
                              <Button
                                outline
                                size="sm"
                                color="success"
                                className="waves-effect waves-light"
                                onClick={() => generateDHAWB(order?.cst_no)}
                                disabled={returnAWBNo ? true : false}
                              >
                                {returnAWBNo === order?.cst_no ? (
                                  <Spinner
                                    size="sm"
                                    style={{ width: 10, height: 10 }}
                                  />
                                ) : (
                                  <i className="bx bx-download"></i>
                                )}
                              </Button>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
        {orderData?.length > 0 ? (
          <DHPagination
            totalPages={listOrders?.recordsTotal}
            currentPage={paginationValue.page}
            setPaginationValue={setPaginationValue}
            setLimitValue={setLimitValue}
          />
        ) : null}
      </div>
    </>
  );
};

export default ReturnOrderList;
