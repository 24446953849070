import React from "react";

import { MetaTags } from "react-meta-tags";
import {
  DashBreadcrumb,
} from "../../../components/Common/Breadcrumb";
import {
    Col,
    Container,
    Row,
  } from "reactstrap";

import CreateQuote from "./components/Create/CreateQuotation.component"
const CreateQuotation = () => {
  const title = "Create Quotation";
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Create Quotation</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Quotations" breadcrumbItem="Create Quotation" />
            </Col>
          </Row>
        </Container>
        <CreateQuote></CreateQuote>
      </div>
    </React.Fragment>
  );
};
export default CreateQuotation;
