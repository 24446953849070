/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link, BrowserRouter as Router } from 'react-router-dom';
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from '../../../components/Common/Breadcrumb';
import Error403 from '../../../components/Common/Error403';
import {
  fetchMarketplaceCount,
  fetchMarketDashboardCount,
  fetchMarketDashboardSummary,
} from '../action/action';
import SpineArea from '../../AllCharts/apex/SplineArea2';
import PieChart from '../../AllCharts/apex/PieChart';
import 'react-datepicker/dist/react-datepicker.css';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import { format } from 'date-fns';
import moment from 'moment';
import UiCareusol from '../../Dashboard/UiCareusol';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { DateRangePicker } from 'rsuite';

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  CardHeader,
  Input,
  Table,
  Form,
  FormGroup,
  InputGroup,
  Label,
} from 'reactstrap';

import CountUp from 'react-countup';

const options = {
  chart: {
    height: 50,
    type: 'line',
    toolbar: { show: false },
  },
  colors: ['#5156be'],
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        },
      },
    },
    marker: {
      show: false,
    },
  },
};

const formatAmount = (amount) => {
  if (amount >= 1000000) {
    return (amount / 1000000).toFixed(1) + 'M'; // For millions
  } else if (amount >= 1000) {
    return (amount / 1000).toFixed(1) + 'K'; // For thousands
  } else {
    return amount.toString(); // For amounts less than 1000
  }
};

const MarketplaceDashboard = () => {
  const dispatch = useDispatch();
  let UserID = '';
  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      UserID = userObj?.user_id;
    }
  }
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startDateGraph, setStartDateGraph] = useState(
    new moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format(
      'YYYY-MM-DD'
    )
  );
  const [endDateGraph, setEndDateGraph] = useState(
    new moment(new Date()).format('YYYY-MM-DD')
  );

  const [range, setRange] = useState({
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    key: 'selection',
  });
  const sdateFormat = new Date(
    Date.now() - 7 * 24 * 60 * 60 * 1000
  ).toLocaleDateString('en-CA');

  const date = new Date();
  const defaultValue = date.toLocaleDateString('en-CA');
  const defaultValue2 = range?.startDate?.toLocaleDateString('en-CA');
  const dateRange = (startDate, endDate) => {
    setRange({
      startDate: startDate,
      endDate: endDate,
      key: 'selection',
    });
  };
  const { country = [] } = useSelector((state) => state.country);
  const currentCountry = localStorage.getItem('WEBSITE_COUNTRY');

  let cntry = currentCountry;
  if (country !== 'All' && country !== null) {
    cntry = country?.toUpperCase();
  }
  let eDate = '';
  let sDate = '';
  useEffect(() => {
    eDate = endDate;
    sDate = startDate;
    if (startDate === '' || startDate === undefined) {
      sDate = new moment(new Date()).format('YYYY-MM-DD');
    }
    if (endDate === '' || endDate === undefined) {
      eDate = new moment(new Date()).format('YYYY-MM-DD');
    }

    fetchMarketplaceCount({
      params: {
        date_from: startDate === '' ? sDate : startDate,
        date_to: eDate === '' ? startDate : eDate,
        country: cntry,
      },
      dispatch,
    });
  }, [endDate, startDate, cntry]);

  const { marketStatus, isStatusLoading } = useSelector(
    (state) => state.marketPlace
  );
  const Total = marketStatus?.data?.All;

  useEffect(() => {
    fetchMarketDashboardCount({
      params: {
        date_from: startDate === '' ? sDate : startDate,
        date_to: eDate === '' ? startDate : eDate,
      },
      dispatch,
    });
  }, [endDate, startDate]);
  const { dasboardStatus, isDasboardLoading } = useSelector(
    (state) => state.marketPlace
  );
  const dashCount = dasboardStatus?.data?.AE;
  const saleSummery = dasboardStatus?.data?.AE;

  let statsDate = '';
  let endsDate = '';
  let isMobileView = 0;
  useEffect(() => {
    localStorage.setItem('startsDate', startDate);
    localStorage.setItem('endsDate', endDate);
  }, []);
  statsDate = localStorage.getItem('startsDate');
  endsDate = localStorage.getItem('endsDate');

  useEffect(() => {
    let eDate = new moment(endDateGraph).format('YYYY-MM-DD');
    let sDate = new moment(startDateGraph).format('YYYY-MM-DD');
    if (
      startDateGraph === '' ||
      startDateGraph === undefined ||
      endDateGraph === '' ||
      endDateGraph === undefined
    ) {
      sDate = new moment(new Date() - 7).format('YYYY-MM-DD');
      eDate = new moment(new Date()).format('YYYY-MM-DD');
    }
    fetchMarketDashboardSummary({
      params: {
        date_from: sDate === '' ? statsDate : sDate,
        date_to: eDate === '' ? endsDate : eDate,
      },
      dispatch,
    });
  }, [endDateGraph, startDateGraph]);
  const { dasboardSummary = [], isSummaryLoading } = useSelector(
    (state) => state.marketPlace
  );
  const splineGraph = dasboardSummary?.data;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | DH OMS - Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Dashboard"
                breadcrumbItem="MarketPlace Dashboard"
              />
            </Col>
            <Col xl={6} className="textAlignRight language">
              <div className="DashbordFliter">
                {isMobileView !== 0 ? (
                  <>
                    <DateRangePicker
                      placement="auto"
                      placeholder="Select Date Range"
                      onClean={() => {
                        setStartDate(
                          new moment(new Date()).format('YYYY-MM-DD')
                        );
                        setEndDate(new moment(new Date()).format('YYYY-MM-DD'));
                      }}
                      showOneCalendar
                      onChange={(selectedDates, instance) => {
                        const dateStr = selectedDates?.[0];
                        const first_Date = new Date(dateStr)?.toString();
                        const firstD = moment(new Date(first_Date))?.format(
                          'YYYY/MM/DD'
                        );
                        const firstDateReplace = firstD?.replaceAll('/', '-');
                        const dateStr1 = selectedDates?.[1];
                        const second_Date = new Date(dateStr1)?.toString();
                        const secondD = moment(new Date(second_Date))?.format(
                          'YYYY/MM/DD'
                        );
                        const secondDateReplace = secondD?.replaceAll('/', '-');
                        setStartDate(firstDateReplace);
                        setEndDate(secondDateReplace);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <DateRangePicker
                      placement="leftStart"
                      placeholder="Select Date Range"
                      onClean={() => {
                        setStartDate(
                          new moment(new Date()).format('YYYY-MM-DD')
                        );
                        setEndDate(new moment(new Date()).format('YYYY-MM-DD'));
                      }}
                      // disabledDate={combine(allowedMaxDays(30), afterToday())}
                      onChange={(selectedDates, instance) => {
                        const dateStr = selectedDates?.[0];
                        const first_Date = new Date(dateStr)?.toString();
                        const firstD = moment(new Date(first_Date))?.format(
                          'YYYY/MM/DD'
                        );
                        const firstDateReplace = firstD?.replaceAll('/', '-');
                        const dateStr1 = selectedDates?.[1];
                        const second_Date = new Date(dateStr1)?.toString();
                        const secondD = moment(new Date(second_Date))?.format(
                          'YYYY/MM/DD'
                        );
                        const secondDateReplace = secondD?.replaceAll('/', '-');
                        setStartDate(firstDateReplace);
                        setEndDate(secondDateReplace);
                      }}
                    />{' '}
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            {UserID !== '9' ? (
              <>
                <Col xl={8} md={7}>
                  <Row className="row-fluid-scroll">
                    {saleSummery?.map((item, index) => (
                      <>
                        <Col xl={4} md={6}>
                          <Card className="card-h-100">
                            <CardBody>
                              <Row className="align-items-center">
                                <Col xs={9}>
                                  <img
                                    src={`https://assets.danubehome.com/media/assets/mktplace/sellers/${item?.seller.toLowerCase()}.png`}
                                    style={{ height: '41px', width: '129px' }}
                                  />
                                </Col>
                                <Col xs={3}>
                                  <span className="DashbordAuto">
                                    <i className="bx bx-trending-up"></i>
                                  </span>
                                </Col>
                              </Row>
                              <div className="text-nowrap">
                                <span>Total {item?.noOfOrders} Orders</span>

                                <h4 className="mb-3">
                                  <span className="counter-value">
                                    AED{' '}
                                    <CountUp
                                      start={0}
                                      end={item?.totalValue}
                                      duration={3}
                                      formattingFn={(value) =>
                                        formatAmount(value)
                                      }
                                    />
                                  </span>
                                </h4>
                                <span className="ms-1 text-muted font-size-13">
                                  <Link to="/marketplaceorders?mid=Amazon">
                                    View All
                                  </Link>
                                </span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </>
                    ))}
                  </Row>
                </Col>
                <Col xl={4} md={5}>
                  <Card className="TotalOrderCard">
                    <CardHeader>
                      <CardTitle>Today UAE Orders</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Open</td>
                              <td>AED {Total?.total_open}</td>
                            </tr>
                            <tr>
                              <td>Posted</td>
                              <td>AED {Total?.total_posted}</td>
                            </tr>
                            <tr className="notLs">
                              <td>Not in LS</td>
                              <td>AED {Total?.total_not_in_ls}</td>
                            </tr>
                            <tr>
                              <td>Pending Payment</td>
                              <td>AED {Total?.total_pending_payment}</td>
                            </tr>
                            <tr>
                              <td>Cancelled</td>
                              <td>AED {Total?.total_canceled}</td>
                            </tr>
                            <tr className="bordertop netTotal">
                              <th>Net Total </th>
                              <th>
                                AED{' '}
                                {Number(
                                  Total?.total_open +
                                    Total?.total_posted +
                                    Total?.total_not_in_ls
                                ).toFixed(2)}
                              </th>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={12}>
                  <Card>
                    <CardHeader className="d-sm-flex align-items-center justify-content-between">
                      <CardTitle>Orders Summary</CardTitle>
                      <Col xl={2}>
                        <div className="InputBg">
                          <i className="bx bx-calendar"></i>
                          <FormGroup>
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block"
                                defaultValue={`${defaultValue} to ${defaultValue2}`}
                                options={{
                                  mode: 'range',
                                  dateFormat: 'Y-m-d',
                                }}
                                //  onChange = {dateRange(format(range?.startDate,'yyyy-MM-dd'),format(range?.endDate,'yyyy-MM-dd'))}

                                onChange={(
                                  selectedDates,
                                  dateStr,
                                  instance
                                ) => {
                                  const date_split = dateStr.split('to');
                                  const firstDate = date_split[0];
                                  setStartDateGraph(firstDate);
                                  const secondDate = date_split[1];
                                  setEndDateGraph(secondDate);
                                }}
                              />
                            </InputGroup>
                          </FormGroup>
                        </div>
                      </Col>
                    </CardHeader>
                    {dasboardSummary?.code === 100 ? (
                      <>
                        <CardBody>
                          <div dir="ltr">
                            <SpineArea splineGraph={splineGraph} />
                          </div>
                        </CardBody>
                      </>
                    ) : (
                      <>
                        <CardBody>
                          <Skeleton count={5} />
                        </CardBody>
                      </>
                    )}
                  </Card>
                </Col>
              </>
            ) : (
              <>
                {' '}
                <Error403 />
              </>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MarketplaceDashboard;
