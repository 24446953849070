import axios from "axios";
import UserAuthKey from "../../../components/Common/UserAuthKey";
import { setExportedReportList } from "../../../Redux/exportedreports/exportedreportSlice";
import { setUserData } from "../../../Redux/user/userSlice";
const apiUrl = process.env.REACT_APP_OMS_API;

export const fetchExportedReportsList = async ({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
        method: 'POST',
        url: `${apiUrl}v1/exported-reports/list`,
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
        },
        data: {
            ...params,
        },
    };
    try {
        const response = await axios(config);
        dispatch(setExportedReportList(response?.data));
    } catch (error) {
        if (error?.response?.status === 401) {
            dispatch(setUserData(null))
            window.location.replace('/logout');
        }
        else {

        }
    }
};

