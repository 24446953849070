import React, { useEffect } from 'react';
import { MetaTags } from 'react-meta-tags';
//import Breadcrumbs
import {
    Breadcrumbs,
    DashBreadcrumb,
} from '../../components/Common/Breadcrumb';

import { Col, Container, Row, FormGroup, InputGroup } from 'reactstrap';
import ExportedReportsList from './components/ExportedReportsList';

const ExportedReports = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Exported Reports View</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Row>
                        <Col xl={6}>
                            <DashBreadcrumb
                                title="Exported Reports"
                                breadcrumbItem="Exported Reports View"
                            />
                        </Col>
                    </Row>
                    <ExportedReportsList />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ExportedReports;
