import React, { useState } from 'react';
import { Col, Row, Form } from 'reactstrap';
import { DateRangePicker } from 'rsuite';
import axios from 'axios';

function ReturnExportReport(props) {
    const { users } = props;
    const [dateRange, setDateRange] = useState(null);
    const [status, setStatus] = useState("all");
    const [loading, setLoading] = useState(false);
    const omsServiceapiUrl = process.env.REACT_APP_OMS_NEST_API;
    console.log(users, 'users');
    const handleExport = async (e) => {
        e.preventDefault();

        if (!dateRange || dateRange.length !== 2) {
            alert("Please select a date range before exporting.");
            return;
        }

        const [dateFrom, dateTo] = dateRange.map(date => date.toISOString().split('T')[0]);

        const payload = {
            exportType: "Returns",
            dateFrom,
            dateTo,
            country: "ae",
            status: status === "All" ? "" : status, // Send empty string if "All" is selected
            userId: users.user_id,
            email: users.user_email,
            name: users.user_name
        };

        setLoading(true);

        try {
            const response = await axios.post(`${omsServiceapiUrl}/automation/returns-report`, payload, {
                headers: { 'Content-Type': 'application/json' }
            });

            if (response.status === 201 && response.data.status === "success") {
                alert(response.data.message); // "Report has been sent to user"
            } else {
                alert("Export failed. Please try again.");
            }
        } catch (error) {
            console.error("Error exporting report:", error);
            alert("An error occurred while exporting.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Row className="align-items-end justify-content-end">
            <Form onSubmit={handleExport}>
                <Col xl={12} className="text-end">
                    <div className="DashbordFliter">
                        <div className="InputBg">
                            <DateRangePicker
                                placement="leftStart"
                                placeholder="Select Date Range"
                                onChange={setDateRange}
                                value={dateRange}
                            />
                        </div>
                        <div className="InputBg">
                            <select
                                className="form-select"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <option value="All">All</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                                <option value="received">Received</option>
                                <option value="authorized">Authorized</option>
                                <option value="out_for_return_pickup">Out for Return Pickup</option>
                                <option value="return_initiated">Return Initiated</option>
                                <option value="return_pickup_fail">Return Pickup Fail</option>
                                <option value="pending">Pending</option>
                                <option value="return_drop_success">Return Drop Success</option>
                                <option value="material_received">Material Received</option>
                                <option value="return_pickup_success">Return Pickup Success</option>
                                <option value="return_rescheduled">Return Rescheduled</option>
                                <option value="return_scheduled">Return Scheduled</option>
                            </select>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-dark d-flex align-items-center"
                            disabled={loading} // Disable button while loading
                        >
                            {loading ? "Exporting..." : "Export"}
                            <i className="bx bx-download ms-2"></i>
                        </button>
                    </div>
                </Col>
            </Form>
        </Row>
    );
}

export default ReturnExportReport;
